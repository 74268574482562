import { customizable, memoizeFunction, mergeStyleSets, IStyle, ITheme, Link } from "office-ui-fabric-react";
import * as React from "react";

export interface ILinkTextStyles {
    root?: IStyle;
}

export interface ILinkTextClassNames {
    root: string;
}

export interface ILinkTextProps {
    text: string;
    singleLine?: boolean;
    className?: string;
    styles?: ILinkTextStyles;
    theme?: ITheme;
}

export const getClassNames = memoizeFunction(
    (theme: ITheme, styles: ILinkTextStyles, className?: string): ILinkTextClassNames => {
        return mergeStyleSets({
            root: [
                styles.root,
                className,
            ],
        });
    });


function renderLine(line: string, index: number, singleLine: boolean): JSX.Element {
    const mdLinkRegEx = new RegExp("\\[(.*?)\\]\\((.*?)\\)","g");

    var outputElements: JSX.Element[] = [];
    var lastIndex = 0;

    var matchIndex = 0;
    var match;
    while ((match = mdLinkRegEx.exec(line)) !== null) {
        const beginningOfMatch = mdLinkRegEx.lastIndex - match[0].length;

        const preText = line.substr(lastIndex, beginningOfMatch-lastIndex);

        outputElements.push(<span key={`${index}${matchIndex++}`}>{preText}</span>);
        outputElements.push(<Link key={`${index}${matchIndex++}`} href={match[2]} target="_blank">{match[1]}</Link>);

        lastIndex = mdLinkRegEx.lastIndex;
    }

    const endString = line.substr(lastIndex);

    if (endString.length > 0) {
        outputElements.push(<span key={`${index}${matchIndex++}`}>{endString}</span>);
    }

    return singleLine ? <>{outputElements}</> : <p key={index}>{outputElements}</p>;
}

@customizable("LinkText", ["theme", "styles"], true)
export class LinkText extends React.Component<ILinkTextProps> {
    render() {
        if (this.props.singleLine === true) {
            return renderLine(this.props.text, 0, true);
        } else {
            return <>{this.props.text.split("\n").map((line, index) => renderLine(line, index, false))}</>;
        }
    }
}