import { customizable, ITextFieldProps, ITheme, MaskedTextField, TextField } from "office-ui-fabric-react";
import * as React from "react";

import { InfoButtonCallout } from "../../../components/InfoButtonCallout";

import { IFieldProps } from "./IFieldProps";
import { getKnownTypeInfo, ITextFieldSchema } from "./formschema/ITextFieldSchema";

export interface ITextFormFieldProps extends IFieldProps<ITextFieldSchema> {
    theme?: ITheme;
}

@customizable("TextFormField", ["theme"], true)
export class TextFormField extends React.Component<ITextFormFieldProps> {
    private onRenderLabel = (props?: ITextFieldProps, defaultRender?: (props?: ITextFieldProps) => JSX.Element | null): JSX.Element | null => {
        if (this.props.schema.tooltipVisible) {
            return (
                <div style={{ display: "flex", alignItems: "center" }}>
                    {defaultRender!(props)}
                    <InfoButtonCallout>
                        {this.props.schema.tooltipText}
                    </InfoButtonCallout>
                </div>
            );
        } else {
            return defaultRender!(props);
        }
    }

    private getErrorMessage = (value: string): string => {
        if ((this.props.schema.characterLimit !== undefined) && (value.length > this.props.schema.characterLimit)) {
            return `This field cannot exceed ${this.props.schema.characterLimit} characters; current character count is ${value.length}.`;
        }

        const knownTypeInfo = getKnownTypeInfo(this.props.schema.knownType);

        if (knownTypeInfo.mask && (knownTypeInfo.skipMaskValidation !== true)) {
            let matchesPattern: boolean = true;

            // Convert escaped mask hints (\9) to a temporary (\u2665)
            // Swap the remaining unescaped mask hints (9) to a marker for comparison (\u2666)
            // Convert the temporary placeholder (\u2665) back to it's unescaped value (9)
            const mask = knownTypeInfo.mask
                .replace(/\\9/g, "\u2665").replace(/9/g, "\u2666").replace(/\u2665/g, "9")
                .replace(/\\a/g, "\u2665").replace(/a/g, "\u2667").replace(/\u2665/g, "a")
                .replace(/\\\*/g, "\u2665").replace(/\*/g, "\u2668").replace(/\u2665/g, "*");

            if (value.length === mask.length) {
                for (let i = 0; i < value.length; i++) {
                    switch (mask[i]) {
                    case "\u2666":
                        if (/[0-9]/.test(value[i])) {
                            continue;
                        }
                        break;
                    case "\u2667":
                        if (/[a-zA-Z]/.test(value[i])) {
                            continue;
                        }
                        break;
                    case "\u2668":
                        if (/[a-zA-Z0-9]/.test(value[i])) {
                            continue;
                        }
                        break;
                    default:
                        if (mask[i] === value[i]) {
                            continue;
                        }
                    }
                    matchesPattern = false;
                    break;
                }
            } else {
                matchesPattern = false;
            }

            if (!matchesPattern) {
                return "This field is invalid.";
            }
        }

        return "";
    }

    private notifyValidationResult = (errorMessage: string, value?: string) => {
        this.props.onValidationChange(this.props.schema.uuid, ((errorMessage === "") && !(this.props.schema.required && (value === ""))));
    }

    render() {
        const knownTypeInfo = getKnownTypeInfo(this.props.schema.knownType);

        const iconProps = knownTypeInfo.iconProps || (this.props.schema.iconName ? {iconName: this.props.schema.iconName} : undefined);

        if (iconProps) {
            iconProps.styles = {
                root: {
                    color: this.props.theme!.palette.neutralTertiary,
                    padding: 0,
                },
            };
        }

        return (
            <div>
                {knownTypeInfo.mask && <MaskedTextField
                    errorMessage={((this.props.value === "") && this.props.schema.required) ? "This field is required." : undefined}
                    label={this.props.schema.titleText}
                    mask={knownTypeInfo.mask}
                    maskChar={knownTypeInfo.maskCharacter}
                    ariaLabel={this.props.schema.placeholderText}
                    required={this.props.schema.required}
                    onRenderLabel={this.onRenderLabel}
                    iconProps={knownTypeInfo.iconProps}
                    value={this.props.value}
                    onChange={(ev, newValue) => {this.props.onValueChange(this.props.schema.uuid, newValue); }}
                    onGetErrorMessage={this.getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut={true}
                    onNotifyValidationResult={this.notifyValidationResult}
                    disabled={this.props.readOnly}
                    />}
                {!knownTypeInfo.mask && <TextField
                    errorMessage={((this.props.value === "") && this.props.schema.required) ? "This field is required." : undefined}
                    label={this.props.schema.titleText}
                    placeholder={this.props.schema.placeholderText}
                    ariaLabel={this.props.schema.placeholderText}
                    required={this.props.schema.required}
                    onRenderLabel={this.onRenderLabel}
                    iconProps={iconProps}
                    multiline={this.props.schema.multiline}
                    autoAdjustHeight={this.props.schema.multiline}
                    resizable={false}
                    onGetErrorMessage={this.getErrorMessage}
                    value={this.props.value}
                    onChange={(ev, newValue) => {this.props.onValueChange(this.props.schema.uuid, newValue); }}
                    onNotifyValidationResult={this.notifyValidationResult}
                    disabled={this.props.readOnly}
                    />}
                
                {this.props.schema.descriptionVisible && <p>{this.props.schema.descriptionText}</p>}
            </div>
        );
    }
}