import { customizable, memoizeFunction, mergeStyleSets, AnimationClassNames, IStyle, ITheme } from "office-ui-fabric-react";
import * as React from "react";


export interface IErrorMessageStyles {
    root?: IStyle;
    errorMessage?: IStyle;
}

export interface IErrorMessageClassNames {
    root: string;
    errorMessage: string;
}

export interface IErrorMessageProps {
    errorMessage: string;
    className?: string;
    styles?: IErrorMessageStyles;
    theme?: ITheme;
}

export const getClassNames = memoizeFunction(
    (theme: ITheme, styles: IErrorMessageStyles, className?: string): IErrorMessageClassNames => {
        return mergeStyleSets({
            root: [
                styles.root,
                className,
            ],
            errorMessage: [
                AnimationClassNames.slideDownIn20,
                theme.fonts.small,
                {
                    color: theme.semanticColors.errorText,
                    margin: 0,
                    paddingTop: 5,
                    display: "flex",
                    alignItems: "center",
                },
              ],
        });
    });

@customizable("ErrorMessage", ["theme", "styles"], true)
export class ErrorMessage extends React.Component<IErrorMessageProps> {
    render() {
        const classNames = getClassNames(this.props.theme!, this.props.styles!, this.props.className);

        return (
            <div role="alert" className={classNames.root}>
                <p className={classNames.errorMessage}>
                    <span data-automation-id="error-message">{this.props.errorMessage}</span>
                </p>
            </div>
        );
    }
}