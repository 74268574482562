import update from "immutability-helper";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import * as React from "react";

import { FieldEditor } from "./FieldEditor";
import { areFieldEditorPropsEqual, IFieldEditorProps } from "./IFieldEditorProps";
import { IFormHeaderSchema } from "./formschema/IFormHeaderSchema";

export interface IFormHeaderFieldEditorProps extends IFieldEditorProps<IFormHeaderSchema> {
}

export class FormHeaderFieldEditor extends React.Component<IFormHeaderFieldEditorProps> {
    shouldComponentUpdate(nextProps: IFormHeaderFieldEditorProps) {
        return !areFieldEditorPropsEqual(this.props, nextProps);
    }

    render() {
        return (
            <FieldEditor<IFormHeaderSchema>
                schema={this.props.schema}
                onSchemaChange={this.props.onSchemaChange}
                columnsOccupiedInRow={this.props.columnsOccupiedInRow}
                excludeDefaultFooterItems={true}
                >
            </FieldEditor>
        );
    }
}