import { createTheme, getColorFromString, isDark, themeRulesStandardCreator, BaseSlots, IColor, ITheme, ThemeGenerator } from "office-ui-fabric-react";

export function generateThemeFromBaseSlots(primaryColor: string | IColor, foregroundColor: string | IColor, backgroundColor: string | IColor): ITheme {
    const backgroundColorConverted = (typeof backgroundColor === "string") ? getColorFromString(backgroundColor) : backgroundColor;

    const themeRules = themeRulesStandardCreator();

    const backgroundIsDark = (backgroundColorConverted) ? isDark(backgroundColorConverted) : false;
    ThemeGenerator.insureSlots(themeRules, backgroundIsDark);

    ThemeGenerator.setSlot(themeRules[BaseSlots[BaseSlots.primaryColor]], primaryColor, backgroundIsDark, true, true);
    ThemeGenerator.setSlot(themeRules[BaseSlots[BaseSlots.foregroundColor]], foregroundColor, backgroundIsDark, true, true);
    ThemeGenerator.setSlot(themeRules[BaseSlots[BaseSlots.backgroundColor]], backgroundColor, backgroundIsDark, true, true);

    const themeAsJson: { [key: string]: string } = ThemeGenerator.getThemeAsJson(themeRules);

    return createTheme({
      ...{ palette: themeAsJson },
      isInverted: backgroundIsDark,
    });
}