import { customizable, memoizeFunction, mergeStyleSets, ICommandBarItemProps, ITheme } from "office-ui-fabric-react";
import * as React from "react";

import { FieldEditor } from "./FieldEditor";
import { areFieldEditorPropsEqual, IFieldEditorProps } from "./IFieldEditorProps";
import { IInstructionsFieldSchema } from "./formschema/IInstructionsFieldSchema";

export interface IInstructionsFormFieldEditorStyles {
}

export interface IInstructionsFormFieldEditorClassNames {
}

export interface IInstructionsFormFieldEditorProps extends IFieldEditorProps<IInstructionsFieldSchema> {
    styles?: IInstructionsFormFieldEditorStyles;
    theme?: ITheme;
}

export const getClassNames = memoizeFunction(
    (theme: ITheme, styles: IInstructionsFormFieldEditorStyles, className?: string): IInstructionsFormFieldEditorClassNames => {
        return mergeStyleSets({
        });
    });

@customizable("InstructionsFormFieldEditor", ["theme", "styles"], true)
export class InstructionsFormFieldEditor extends React.Component<IInstructionsFormFieldEditorProps> {
    shouldComponentUpdate(nextProps: IInstructionsFormFieldEditorProps) {
        return !areFieldEditorPropsEqual(this.props, nextProps);
    }

    render() {
        const classNames = getClassNames(this.props.theme!, this.props.styles!);

        let footerItems: ICommandBarItemProps[] = [
        ];
        return (
            <FieldEditor<IInstructionsFieldSchema>
                schema={this.props.schema}
                onSchemaChange={this.props.onSchemaChange}
                columnsOccupiedInRow={this.props.columnsOccupiedInRow}
                footerItems={footerItems}
                excludeDefaultFooterItems={true}
                allowTitle={false}
                allowSize={true}
                >
            </FieldEditor>
        );
    }
}