import update from "immutability-helper";
import { customizable, memoizeFunction, mergeStyleSets, ICommandBarItemProps, IContextualMenuItem, IStyle, ITheme, MaskedTextField, TextField } from "office-ui-fabric-react";
import * as React from "react";

import { IconPickerButton } from "../../../components/IconPickerButton";
import { ToggleCommandBarButton } from "../../../components/ToggleCommandBarButton";

import { FieldEditor } from "./FieldEditor";
import { areFieldEditorPropsEqual, IFieldEditorProps } from "./IFieldEditorProps";
import { getKnownTypeInfo, knownTypes, knownTypeToString, ITextFieldSchema, TextFieldSchemaKnownType } from "./formschema/ITextFieldSchema";

export interface ITextFormFieldEditorStyles {
    placeholderTextField?: IStyle;
    iconPicker?: IStyle;
}

export interface ITextFormFieldEditorClassNames {
    placeholderTextField: string;
    iconPicker: string;
}

export interface ITextFormFieldEditorProps extends IFieldEditorProps<ITextFieldSchema> {
    styles?: ITextFormFieldEditorStyles;
    theme?: ITheme;
}

export const getClassNames = memoizeFunction(
    (theme: ITheme, styles: ITextFormFieldEditorStyles, className?: string): ITextFormFieldEditorClassNames => {
        return mergeStyleSets({
            placeholderTextField: [
                {
                    display: "inline-block",
                    width: "calc(100% - 32px)",
                },
                styles.placeholderTextField,
            ],
            iconPicker: [
                {
                    display: "inline-block",
                    verticalAlign: "top",
                },
                styles.iconPicker,
            ],
        });
    });

@customizable("TextFormFieldEditor", ["theme", "styles"], true)
export class TextFormFieldEditor extends React.Component<ITextFormFieldEditorProps> {
    shouldComponentUpdate(nextProps: ITextFormFieldEditorProps) {
        return !areFieldEditorPropsEqual(this.props, nextProps);
    }

    render() {
        const classNames = getClassNames(this.props.theme!, this.props.styles!);
        const knownTypeInfo = getKnownTypeInfo(this.props.schema.knownType);

        let footerItems: ICommandBarItemProps[] = [];

        if (this.props.schema.locked !== true) {
            footerItems.push({
                key: "knownType",
                name: "Type",
                iconProps: {iconName: "fa-book"},
                canCheck: false,
                subMenuProps: {
                    items: knownTypes().map((knownType: TextFieldSchemaKnownType): IContextualMenuItem => {
                        const knownTypeInfo = getKnownTypeInfo(knownType);
                        return {
                            key: knownTypeToString(knownType),
                            text: knownTypeInfo.text,
                            iconProps: knownTypeInfo.iconProps,
                            canCheck: true,
                            checked: (this.props.schema.knownType === knownType),
                            onClick: () => this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {knownType: {$set: knownType}})),
                        };
                    }),
                },
            });
        }

        // Command bar items don't allow disable, so just remove multi-line if it's not supported
        if (!knownTypeInfo.mask) {
            if (this.props.schema.locked !== true) {
                footerItems.push({
                    key: "multiline",
                    name: "Multi-line",
                    canCheck: true,
                    checked: this.props.schema.multiline,
                    onClick: () => this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {multiline: {$set: !this.props.schema.multiline}})),
                    commandBarButtonAs: ToggleCommandBarButton,
                });
            }

            footerItems.push({
                key: "characterlimit",
                name: "Character Limit",
                iconProps: {iconName: "fa-text-width"},
                canCheck: true,
                checked: (this.props.schema.characterLimit !== undefined),
                onClick: () => this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {characterLimit: {$set: (this.props.schema.characterLimit !== undefined) ? undefined : 500}})),
                commandBarButtonAs: ToggleCommandBarButton,
            });
        }

        const iconName = (knownTypeInfo.iconProps) ? knownTypeInfo.iconProps.iconName : this.props.schema.iconName;

        return (
            <FieldEditor<ITextFieldSchema>
                schema={this.props.schema}
                onSchemaChange={this.props.onSchemaChange}
                columnsOccupiedInRow={this.props.columnsOccupiedInRow}
                footerItems={footerItems}
                >
                {knownTypeInfo.mask && <MaskedTextField
                    className="FormEditor-nodrag"
                    mask={knownTypeInfo.mask}
                    disabled={true}
                    ariaLabel="Enter field title here"
                    iconProps={knownTypeInfo.iconProps}
                    value={this.props.schema.placeholderText}
                    onChange={(event, newValue) => this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {placeholderText: {$set: newValue}}))}
                    />}
                {!knownTypeInfo.mask && <div className="TextFormFieldEditor-placeholderTextRow">
                    <TextField
                        className={`${classNames.placeholderTextField} FormEditor-nodrag`}
                        placeholder="Placeholder text"
                        ariaLabel="Enter field title here"
                        value={this.props.schema.placeholderText}
                        onChange={(event, newValue) => this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {placeholderText: {$set: newValue}}))}
                        />
                        <div className={classNames.iconPicker}>
                            <IconPickerButton
                                icon={iconName}
                                disabled={!!knownTypeInfo.iconProps}
                                onChange={(iconName) => this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {iconName: {$set: iconName}}))}
                                />
                        </div>
                    </div>}
                {(this.props.schema.characterLimit !== undefined) && <MaskedTextField
                    className="FormEditor-nodrag"
                    styles={{
                        root: {
                            marginTop: "4px",
                        },
                    }}
                    mask="9999999"
                    maskChar=" "
                    value={this.props.schema.characterLimit.toString()}
                    label="Character Limit"
                    onChange={(event, newValue) => {
                        const newValueNumber = parseInt(newValue || "NaN");
                        this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {characterLimit: {$set: isNaN(newValueNumber) ? 0 : newValueNumber}}));
                    }}
                    onBlur={(event) => {}}
                    />}
            </FieldEditor>
        );
    }
}