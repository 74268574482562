export enum FieldType {
    MultipleChoice,
    Text,
    Header,
    Captcha,
    AcceptTerms,
    Instructions,
}

export function fieldTypes(): FieldType[] {
    return Object.keys(FieldType).map((fieldTypeKey: string) => FieldType[fieldTypeKey]).filter((value: any) => (typeof value === "number"));
}