import { IIconProps } from "office-ui-fabric-react/lib/Icon";

import { extend } from "../../../../utils/extend";

import { FieldType } from "./FieldType";
import { CreateNewFieldSchema, IFieldSchema } from "./IFieldSchema";

export interface ITextFieldSchema extends IFieldSchema {
    placeholderText: string;
    iconName?: string;
    multiline: boolean;
    knownType: TextFieldSchemaKnownType;
    characterLimit?: number;
}

export function CreateNewTextFieldSchema(knownType: TextFieldSchemaKnownType = TextFieldSchemaKnownType.Custom): ITextFieldSchema {
    const knownTypeInfo = getKnownTypeInfo(knownType);

    let newSchema = extend(CreateNewFieldSchema(FieldType.Text), {
        placeholderText: "",
        knownType: knownType,
        multiline: false,
    });

    if (!knownTypeInfo.skipDefaultText) {
        newSchema.titleText = knownTypeInfo.text;
    }

    return newSchema;
}

export enum TextFieldSchemaKnownType {
    Custom,
    FirstName,
    LastName,
    Email,
    Phone,
    Url,
    ProposalName,
    FundingRequest,
    ValueProposition,
}

export function knownTypeToString(knownType: TextFieldSchemaKnownType): string {
    return TextFieldSchemaKnownType[knownType];
}

export function knownTypes(): TextFieldSchemaKnownType[] {
    return Object.keys(TextFieldSchemaKnownType).map((knownTypeKey: string) => TextFieldSchemaKnownType[knownTypeKey]).filter((value: any) => (typeof value === "number"));
}

export interface ITextFieldSchemaKnownTypeInfo {
    text: string;
    iconProps?: IIconProps;
    skipDefaultText?: boolean;
    mask?: string;
    maskCharacter?: string;
    skipMaskValidation?: boolean;
}

export function getKnownTypeInfo(knownType: TextFieldSchemaKnownType): ITextFieldSchemaKnownTypeInfo {
    switch (knownType) {
        case TextFieldSchemaKnownType.Custom:
            return {
                text: "Custom",
                skipDefaultText: true,
            };
        case TextFieldSchemaKnownType.FirstName:
            return {
                text: "First Name",
                iconProps: {iconName: "fa-user"},
            };
        case TextFieldSchemaKnownType.LastName:
            return {
                text: "Last Name",
                iconProps: {iconName: "fa-user"},
            };
        case TextFieldSchemaKnownType.Email:
            return {
                text: "Email",
                iconProps: {iconName: "fa-envelope"},
            };
        case TextFieldSchemaKnownType.Phone:
            return {
                text: "Phone",
                iconProps: {iconName: "fa-phone"},
                mask: "(999) 999 - 9999",
            };
        case TextFieldSchemaKnownType.Url:
            return {
                text: "URL",
                iconProps: {iconName: "fa-external-link"},
            };
        case TextFieldSchemaKnownType.ProposalName:
            return {
                text: "Proposal Name",
            };
        case TextFieldSchemaKnownType.FundingRequest:
            return {
                text: "Funding Request",
                iconProps: {iconName: "fa-dollar"},
                mask: "9999999999",
                maskCharacter: " ",
                skipMaskValidation: true,
            };
        case TextFieldSchemaKnownType.ValueProposition:
            return {
                text: "Value Proposition",
                iconProps: {iconName: "fa-briefcase"},
            };
    }
}