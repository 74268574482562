import { customizable, ITheme } from "office-ui-fabric-react";
import * as React from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { ErrorMessage } from "../../ErrorMessage";

import { IFieldProps } from "./IFieldProps";
import { ICaptchaFieldSchema } from "./formschema/ICaptchaFieldSchema";

export interface ICaptchaFormFieldProps extends IFieldProps<ICaptchaFieldSchema> {
    theme?: ITheme;
}

@customizable("CaptchaFormField", ["theme"], true)
export class CaptchaFormField extends React.Component<ICaptchaFormFieldProps> {
    render() {
        const errorMessage = (this.props.value === "") ? "This field is required." : undefined;

        if (this.props.readOnly) {
            return null;
        } else {
            return (
                <div>
                    <ReCAPTCHA
                        sitekey="6LdsSzgUAAAAAO6SEbvvWMBMPFgXGDPFm83ST3Wm"
                        onChange={(value) => {
                            this.props.onValueChange(this.props.schema.uuid, value ? value : "");
                            this.props.onValidationChange(this.props.schema.uuid, !!value);
                        }}
                        />
                    {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                </div>
            );
        }
        
    }
}