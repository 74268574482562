
import { customizable, memoizeFunction, mergeStyleSets, Callout, IconButton, IStyle, ITheme } from "office-ui-fabric-react";
import * as React from "react";

export interface IInfoButtonCalloutStyles {
    root?: IStyle;
    calloutBody?: IStyle;
}

export interface IInfoButtonCalloutClassNames {
    root: string;
    callout: string;
    calloutBody: string;
}

export interface IInfoButtonCalloutProps {
    className?: string;
    styles?: IInfoButtonCalloutStyles;
    theme?: ITheme;
}

interface IInfoButtonCalloutState {
    isCalloutVisible: boolean;
}

export const getClassNames = memoizeFunction(
    (theme: ITheme, styles: IInfoButtonCalloutStyles, className?: string): IInfoButtonCalloutClassNames => {
        return mergeStyleSets({
            root: [
                styles.root,
                className,
            ],
            callout: [
                {
                    maxWidth: "400px",
                },
            ],
            calloutBody: [
                {
                    
                    padding: "10px",
                    // whiteSpace: "pre",
                },
                styles.calloutBody,
            ],
        });
    });

@customizable("InfoButtonCallout", ["theme", "styles"], true)
export class InfoButtonCallout extends React.Component<IInfoButtonCalloutProps, IInfoButtonCalloutState> {
    constructor(props: IInfoButtonCalloutProps) {
        super(props);
        this.state = {
            isCalloutVisible: false,
        };
    }

    private _iconButtonElement: HTMLElement | undefined;

    private _onClick = (): void => {
        this.setState({
            isCalloutVisible: !this.state.isCalloutVisible,
        });
    }
    
    private _onDismiss = (): void => {
        this.setState({
            isCalloutVisible: false,
        });
    }

    render() {
        const classNames = getClassNames(this.props.theme!, this.props.styles!, this.props.className);
        return (
            <>
                <span
                    className={classNames.root}
                    ref={menuButton => (this._iconButtonElement = menuButton!)}
                    >
                    <IconButton iconProps={{ iconName: "fa-info-circle" }} title="Info" ariaLabel="Info" onClick={this._onClick} />
                </span>
                {this.state.isCalloutVisible && (
                    <Callout target={this._iconButtonElement} onDismiss={this._onDismiss} className={classNames.callout}>
                        <div className={classNames.calloutBody}>
                            {this.props.children}
                        </div>
                    </Callout>
                )}
            </>
        );
    }
}