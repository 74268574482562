import { extend } from "../../../../utils/extend";

import { FieldType } from "./FieldType";
import { CreateNewFieldSchema, IFieldSchema } from "./IFieldSchema";

export interface ICaptchaFieldSchema extends IFieldSchema {
    
}

export function CreateNewCaptchaFieldSchema(): ICaptchaFieldSchema {
    let newSchema = extend(CreateNewFieldSchema(FieldType.Captcha), {
        required: true,
    });

    return newSchema;
}