import { customizable, ITheme } from "office-ui-fabric-react";
import * as React from "react";

import { LinkText } from "../../LinkText";

import { IInstructionsFieldSchema } from "./formschema/IInstructionsFieldSchema";

export interface IInstructionsFormFieldProps {
    schema: IInstructionsFieldSchema;
    theme?: ITheme;
}

@customizable("InstructionsFormField", ["theme"], true)
export class InstructionsFormField extends React.Component<IInstructionsFormFieldProps> {
    render() {
        return <LinkText text={this.props.schema.descriptionText} />;
    }
}