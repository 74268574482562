/**
 * Simulated enum for keycodes. These will get inlined by uglify when used much like an enum
 *
 * @public
 */
export const KeyCodes = {
  backspace: 8 as 8,
  tab: 9 as 9,
  enter: 13 as 13,
  shift: 16 as 16,
  ctrl: 17 as 17,
  alt: 18 as 18,
  pauseBreak: 19 as 19,
  capslock: 20 as 20,
  escape: 27 as 27,
  space: 32 as 32,
  pageUp: 33 as 33,
  pageDown: 34 as 34,
  end: 35 as 35,
  home: 36 as 36,
  left: 37 as 37,
  up: 38 as 38,
  right: 39 as 39,
  down: 40 as 40,
  insert: 45 as 45,
  del: 46 as 46,
  zero: 48 as 48,
  one: 49 as 49,
  two: 50 as 50,
  three: 51 as 51,
  four: 52 as 52,
  five: 53 as 53,
  six: 54 as 54,
  seven: 55 as 55,
  eight: 56 as 56,
  nine: 57 as 57,
  a: 65 as 65,
  b: 66 as 66,
  c: 67 as 67,
  d: 68 as 68,
  e: 69 as 69,
  f: 70 as 70,
  g: 71 as 71,
  h: 72 as 72,
  i: 73 as 73,
  j: 74 as 74,
  k: 75 as 75,
  l: 76 as 76,
  m: 77 as 77,
  n: 78 as 78,
  o: 79 as 79,
  p: 80 as 80,
  q: 81 as 81,
  r: 82 as 82,
  s: 83 as 83,
  t: 84 as 84,
  u: 85 as 85,
  v: 86 as 86,
  w: 87 as 87,
  x: 88 as 88,
  y: 89 as 89,
  z: 90 as 90,
  leftWindow: 91 as 91,
  rightWindow: 92 as 92,
  select: 93 as 93,
  zero_numpad: 96 as 96,
  one_numpad: 97 as 97,
  two_numpad: 98 as 98,
  three_numpad: 99 as 99,
  four_numpad: 100 as 100,
  five_numpad: 101 as 101,
  six_numpad: 102 as 102,
  seven_numpad: 103 as 103,
  eight_numpad: 104 as 104,
  nine_numpad: 105 as 105,
  multiply: 106 as 106,
  add: 107 as 107,
  subtract: 109 as 109,
  decimalPoint: 110 as 110,
  divide: 111 as 111,
  f1: 112 as 112,
  f2: 113 as 113,
  f3: 114 as 114,
  f4: 115 as 115,
  f5: 116 as 116,
  f6: 117 as 117,
  f7: 118 as 118,
  f8: 119 as 119,
  f9: 120 as 120,
  f10: 121 as 121,
  f11: 122 as 122,
  f12: 123 as 123,
  numlock: 144 as 144,
  scrollLock: 145 as 145,
  semicolon: 186 as 186,
  equalSign: 187 as 187,
  comma: 188 as 188,
  dash: 189 as 189,
  period: 190 as 190,
  forwardSlash: 191 as 191,
  graveAccent: 192 as 192,
  openBracket: 219 as 219,
  backSlash: 220 as 220,
  closeBracket: 221 as 221,
  singleQuote: 222 as 222
};
export type KeyCodes = number;
