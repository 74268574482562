import { extend } from "../../../../utils/extend";

import { FieldType } from "./FieldType";
import { CreateNewFieldSchema, IFieldSchema } from "./IFieldSchema";

export interface IFormHeaderSchema extends IFieldSchema {
}

export function CreateNewFormHeaderSchema(): IFormHeaderSchema {
    return extend(CreateNewFieldSchema(FieldType.Header), {
    });
}