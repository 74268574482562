import update from "immutability-helper";
import { Pivot, PivotItem, PrimaryButton, TextField } from "office-ui-fabric-react";
import * as React from "react";
import "whatwg-fetch";

import { DynamicForm } from "./components/DynamicForm/DynamicForm";
import { DynamicFormEditor } from "./components/DynamicForm/DynamicFormEditor";
import { FormSchemaRow } from "./components/DynamicForm/formcomponents/formschema/FormSchemaRow";

export interface IEditFormComponentProps {
    defaultName: string;
    defaultSchema: string;
    onSave: (newName:string, newSchema:string) => void;
}

export interface IEditFormComponentState {
    name: string;
    schema: FormSchemaRow[];
}

export class EditFormComponent extends React.Component<IEditFormComponentProps, IEditFormComponentState> {
    
    constructor(props: IEditFormComponentProps) {
        super(props);

        this.state = {
            name: this.props.defaultName,
            schema: FormSchemaRow.parseRows(JSON.parse(this.props.defaultSchema)),
        };
    }

    render() {
        return (
            <div>
                <TextField
                    value={this.state.name}
                    label="Form Name"
                    styles={{root: {marginBottom: "10px"}}}
                    onChange={(event, newValue?:string) => this.setState(update(this.state, {name: {$set: newValue}}))}
                    />
                <Pivot>
                    <PivotItem headerText="Edit">
                        <DynamicFormEditor
                            schemaRows={this.state.schema}
                            onSchemaRowsChange={(newSchema) => this.setState(update(this.state, {schema: {$set: newSchema}}))}
                            styles={{
                                root: {
                                    marginTop: "10px",
                                },
                            }}
                            />
                    </PivotItem>
                    <PivotItem linkText="Preview">
                        <DynamicForm
                            schemaRows={this.state.schema}
                            data={{}}
                            onDataChange={(fieldUuid, newValue) => {}}
                            onValidationChange={(fieldUuid, valid) => {}}
                            />
                    </PivotItem>
                </Pivot>
                <div style={{textAlign: "right"}}>
                    <PrimaryButton text="Save" onClick={() => this.props.onSave(this.state.name, JSON.stringify(this.state.schema))} />
                </div>
            </div>
        );
    }
}