import { IFieldSchema } from "./formschema/IFieldSchema";

export interface IFieldEditorProps<SchemaType extends IFieldSchema> {
    schema: SchemaType;
    onSchemaChange: (fieldUuid: string, newSchema: SchemaType | null) => void;
    columnsOccupiedInRow: number;
}

export function areFieldEditorPropsEqual<SchemaType extends IFieldSchema>(a: IFieldEditorProps<SchemaType>, b: IFieldEditorProps<SchemaType>): boolean {
    const isSchemaEqual = (JSON.stringify(a.schema) === JSON.stringify(b.schema));
    const isOnSchemaChangeEqual = (a.onSchemaChange === b.onSchemaChange);
    const isColumnsOccupiedInRowEqual = (a.columnsOccupiedInRow === b.columnsOccupiedInRow);

    return (isSchemaEqual && isOnSchemaChangeEqual && isColumnsOccupiedInRowEqual);
}