import { customizable, memoizeFunction, mergeStyleSets, Callout, IconButton, IStyle, ITheme } from "office-ui-fabric-react";
import * as React from "react";

import { fontAwesomeIcons } from "../utils/registerFontAwesome";

export interface IIconPickerButtonStyles {
    root?: IStyle;
    calloutBody?: IStyle;
}

export interface IIconPickerButtonClassNames {
    root: string;
    calloutBody: string;
}

export interface IIconPickerButtonProps {
    defaultIcon?: string;
    icon?: string;
    onChange?: (icon?: string) => void;
    disabled?: boolean;
    className?: string;
    styles?: IIconPickerButtonStyles;
    theme?: ITheme;
}

interface IIconPickerButtonState {
    isCalloutVisible: boolean;
    icon?: string;
}

export const getClassNames = memoizeFunction(
    (theme: ITheme, styles: IIconPickerButtonStyles, className?: string): IIconPickerButtonClassNames => {
        return mergeStyleSets({
            root: [
                styles.root,
                className,
            ],
            calloutBody: [
                {
                    display: "flex",
                    flexWrap: "wrap",
                },
                styles.calloutBody,
            ],
        });
    });

@customizable("IconPickerButton", ["theme", "styles"], true)
export class IconPickerButton extends React.Component<IIconPickerButtonProps, IIconPickerButtonState> {
    constructor(props: IIconPickerButtonProps) {
        super(props);
        this.state = {
            isCalloutVisible: false,
        };
    }

    private _iconButtonElement: HTMLElement | undefined;

    private openIconSelector = (): void => {
        this.setState({
            isCalloutVisible: !this.state.isCalloutVisible,
        });
    }
    
    private onDismissCallout = (): void => {
        this.setState({
            isCalloutVisible: false,
        });
    }

    private onSelectIcon = (icon?: string): void => {
        this.setState({
            isCalloutVisible: false,
            icon: icon,
        });

        if (this.props.onChange) {
            this.props.onChange(icon);
        }
    }

    private getIconTitle = (icon: string): string => {
        return icon.split("-").filter((value, index) => (index > 0)).map((value) => `${value[0].toUpperCase()}${value.substr(1)}`).join(" ");
    }

    render() {
        const classNames = getClassNames(this.props.theme!, this.props.styles!, this.props.className);
        const selectedIconName = this.props.icon || this.state.icon || this.props.defaultIcon || undefined;

        return (
            <>
                <span
                    className={classNames.root}
                    ref={menuButton => (this._iconButtonElement = menuButton!)}>
                    <IconButton
                        iconProps={{ iconName: selectedIconName || "fa-crosshairs" }}
                        title="Select Icon"
                        ariaLabel="Select Icon"
                        onClick={this.openIconSelector}
                        disabled={this.props.disabled}
                        />
                </span>
                {this.state.isCalloutVisible && (
                    <Callout
                        target={this._iconButtonElement}
                        onDismiss={this.onDismissCallout}
                        calloutMaxWidth={275}
                        calloutMaxHeight={275}
                        preventDismissOnScroll={true}
                        >
                        <div className={classNames.calloutBody}>
                            <IconButton
                                title="None"
                                onClick={() => this.onSelectIcon()}
                                />
                            {Object.keys(fontAwesomeIcons).map((iconName: string): JSX.Element => (
                                <IconButton
                                    key={iconName}
                                    iconProps={{ iconName: iconName }}
                                    title={this.getIconTitle(iconName)}
                                    onClick={() => this.onSelectIcon(iconName)}
                                    />
                            ))}
                        </div>
                    </Callout>
                )}
            </>
        );
    }
}