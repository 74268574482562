import { loadTheme } from "office-ui-fabric-react";
import "whatwg-fetch";

import * as DynamicFormViewComponentImports from "./DynamicFormViewComponent";
import * as EditFormComponentImports from "./EditFormComponent";
import * as ViewAllScreeningComponentImports from "./ViewAllScreeningsComponent";
import { generateThemeFromBaseSlots } from "./utils/generateThemeFromBaseSlots";
import { registerFontAwesome } from "./utils/registerFontAwesome";

const exoTheme = generateThemeFromBaseSlots("#0c658f", "#333333", "#FFFFFF");
loadTheme(exoTheme);

registerFontAwesome();

global["ReactComponents"] = {};
Object.assign(global["ReactComponents"], EditFormComponentImports);
Object.assign(global["ReactComponents"], ViewAllScreeningComponentImports);
Object.assign(global["ReactComponents"], DynamicFormViewComponentImports);