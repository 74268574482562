export class Point {
    public x: Readonly<number>;
    public y: Readonly<number>;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    public plus(other: Point): Point {
        return new Point(this.x + other.x, this.y + other.y);
    }

    public times(other: Point): Point {
        return new Point(this.x * other.x, this.y * other.y);
    }

    public timesScalar(scalar: number): Point {
        return new Point(this.x * scalar, this.y * scalar);
    }
}

export class Size {
    public width: Readonly<number>;
    public height: Readonly<number>;

    constructor(width: number, height: number) {
        this.width = width;
        this.height = height;
    }

    public plus(other: Size): Point {
        return new Point(this.width + other.width, this.height + other.height);
    }
}

export class Rect {
    public x: Readonly<number>;
    public y: Readonly<number>;
    public width: Readonly<number>;
    public height: Readonly<number>;

    constructor(x: number, y: number, width: number, height: number) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }

    public translate(pt: Point): Rect {
        return new Rect(this.x + pt.x, this.y + pt.y, this.width, this.height);
    }

    public getPoint(): Point {
        return new Point(this.x, this.y);
    }

    public getSize(): Size {
        return new Size(this.width, this.height);
    }

    public static fromClientRect(clientRect: ClientRect | DOMRect): Rect {
        return new Rect(clientRect.left, clientRect.top, clientRect.width, clientRect.height);
    }
}