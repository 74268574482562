import { extend } from "../../../../utils/extend";

import { FieldType } from "./FieldType";
import { CreateNewFieldSchema, IFieldSchema } from "./IFieldSchema";
import { CreateNewMultipleChoiceFieldOptionSchema, IMultipleChoiceFieldOptionSchema } from "./IMultipleChoiceFieldOptionSchema";

export interface IMultipleChoiceFieldSchema extends IFieldSchema {
    options: IMultipleChoiceFieldOptionSchema[];
    allowMultiple: boolean;
}

export function CreateNewMultipleChoiceSchema(): IMultipleChoiceFieldSchema {
    return extend(CreateNewFieldSchema(FieldType.MultipleChoice), {
        options: [
            CreateNewMultipleChoiceFieldOptionSchema(),
            CreateNewMultipleChoiceFieldOptionSchema(),
            CreateNewMultipleChoiceFieldOptionSchema(),
            CreateNewMultipleChoiceFieldOptionSchema(),
        ],
        allowMultiple: false,
    });
}