import * as React from "react";

import { DynamicForm, IFormData } from "./components/DynamicForm/DynamicForm";
import { FormSchemaRow } from "./components/DynamicForm/formcomponents/formschema/FormSchemaRow";

interface IUser {
    email: string;
    familyName: string;
    givenName: string;
    name: string;
}

// TODO: Partial interface
interface IPool {
    investors: any[];
    name: string;
    openForScreening: boolean;
    openForSubmission: boolean;
    owner: IUser;
    ownerGroupId: number;
    poolId: number;
    proposals: any[];
    screeningFormId: number;
}

interface IExtendedInfo {
    schema: FormSchemaRow[];
    data: IFormData;
}

// TODO: Partial interface
interface IPopupProposal {
    id: number;
    fundingRequest: number;
    name: string;
    investorInfo: any;
    isPublished: boolean;
    isScreening: boolean;
    owner: IUser;
    pools: IPool[];
    promotedFromScreening: boolean;
    valueProposition: string;
    extendedInfo: IExtendedInfo;
}

export interface IDynamicFormViewComponentProps {
    proposal?: IPopupProposal;
}

export interface IDynamicFormViewComponentState {
}

export class DynamicFormViewComponent extends React.Component<IDynamicFormViewComponentProps, IDynamicFormViewComponentState> {
    constructor(props: IDynamicFormViewComponentProps) {
        super(props);

        this.state = {
        };
    }

    render() {
        return this.props.proposal && <DynamicForm
                    schemaRows={this.props.proposal.extendedInfo.schema}
                    data={this.props.proposal.extendedInfo.data}
                    onDataChange={(fieldUuid, newValue) => {}}
                    onValidationChange={(fieldUuid, valid) => {}}
                    readOnly={true}
                    />;
    }
}