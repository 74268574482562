import { customizable, memoizeFunction, mergeStyleSets, ICommandBarItemProps, ITheme } from "office-ui-fabric-react";
import * as React from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { FieldEditor } from "./FieldEditor";
import { areFieldEditorPropsEqual, IFieldEditorProps } from "./IFieldEditorProps";
import { ICaptchaFieldSchema } from "./formschema/ICaptchaFieldSchema";

export interface ICaptchaFormFieldEditorStyles {
}

export interface ICaptchaFormFieldEditorClassNames {
}

export interface ICaptchaFormFieldEditorProps extends IFieldEditorProps<ICaptchaFieldSchema> {
    styles?: ICaptchaFormFieldEditorStyles;
    theme?: ITheme;
}

export const getClassNames = memoizeFunction(
    (theme: ITheme, styles: ICaptchaFormFieldEditorStyles, className?: string): ICaptchaFormFieldEditorClassNames => {
        return mergeStyleSets({
        });
    });

@customizable("CaptchaFormFieldEditor", ["theme", "styles"], true)
export class CaptchaFormFieldEditor extends React.Component<ICaptchaFormFieldEditorProps> {
    shouldComponentUpdate(nextProps: ICaptchaFormFieldEditorProps) {
        return !areFieldEditorPropsEqual(this.props, nextProps);
    }

    render() {
        const classNames = getClassNames(this.props.theme!, this.props.styles!);

        let footerItems: ICommandBarItemProps[] = [
        ];
        return (
            <FieldEditor<ICaptchaFieldSchema>
                schema={this.props.schema}
                onSchemaChange={this.props.onSchemaChange}
                columnsOccupiedInRow={this.props.columnsOccupiedInRow}
                footerItems={footerItems}
                excludeDefaultFooterItems={true}
                allowTitle={false}
                >
                <div>
                    <ReCAPTCHA
                        sitekey="6LdsSzgUAAAAAO6SEbvvWMBMPFgXGDPFm83ST3Wm"
                        />
                </div>
            </FieldEditor>
        );
    }
}