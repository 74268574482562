import { customizable, memoizeFunction, mergeStyleSets, ICommandBarItemProps, IComponentAsProps, IStyle, ITheme, Toggle } from "office-ui-fabric-react";
import * as React from "react";

export interface IToggleCommandBarButtonStyles {
    root?: IStyle;
    container?: IStyle;
}

export interface IToggleCommandBarButtonClassNames {
    root: string;
    container: string;
}

export interface IToggleCommandBarButtonProps extends IComponentAsProps<ICommandBarItemProps> {
    className?: string;
    styles?: IToggleCommandBarButtonStyles;
    theme?: ITheme;
}

export const getClassNames = memoizeFunction(
    (theme: ITheme, styles: IToggleCommandBarButtonStyles, className?: string): IToggleCommandBarButtonClassNames => {
        return mergeStyleSets({
            root: [
                styles.root,
                className,
            ],
            container: [
                {
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "10px",
                },
                styles.container,
            ],
        });
    });

@customizable("ToggleCommandBarButton", ["theme", "styles"], true)
export class ToggleCommandBarButton extends React.Component<IToggleCommandBarButtonProps> {
    render() {
        const classNames = getClassNames(this.props.theme!, this.props.styles!, this.props.className);
        return <Toggle
            className={classNames.root}
            styles={{
                container: [
                    classNames.container,
                ],
            }}
            checked={this.props.checked}
            onText={this.props.text}
            offText={this.props.text}
            onChange={() => {
                if (this.props.onClick) {
                    this.props.onClick();
                }
            }}
            />;
    }
}