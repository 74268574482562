export enum FieldSize {
    Quarter,
    Third,
    Half,
    Full,
}

// Returns bootstrap style column counts (1-12)
export function fieldSizeToColumns(fieldSize: FieldSize): number {
    switch (fieldSize) {
    case FieldSize.Quarter:
        return 3;
    case FieldSize.Third:
        return 4;
    case FieldSize.Half:
        return 6;
    case FieldSize.Full:
        return 12;
    }
}

export function fieldSizeToString(fieldSize: FieldSize): string {
    return FieldSize[fieldSize];
}

export function fieldSizes(): FieldSize[] {
    return Object.keys(FieldSize).map((fieldSizeKey: string) => FieldSize[fieldSizeKey]).filter((value: any) => (typeof value === "number"));
}