import * as UUID from "uuid";

export interface IMultipleChoiceFieldOptionSchema {
    uuid: string;
    text: string;
}

export function CreateNewMultipleChoiceFieldOptionSchema(): IMultipleChoiceFieldOptionSchema {
    return {
        uuid: UUID.v4(),
        text: "",
    };
}