import { getId, DefaultButton, Modal } from "office-ui-fabric-react";
import * as React from "react";

import { DynamicForm, IFormData } from "./components/DynamicForm/DynamicForm";
import { FormSchemaRow } from "./components/DynamicForm/formcomponents/formschema/FormSchemaRow";

interface IUser {
    email: string;
    familyName: string;
    givenName: string;
    name: string;
}

// TODO: Partial interface
interface IPool {
    investors: any[];
    name: string;
    openForScreening: boolean;
    openForSubmission: boolean;
    owner: IUser;
    ownerGroupId: number;
    poolId: number;
    proposals: any[];
    screeningFormId: number;
}

interface IExtendedInfo {
    schema: FormSchemaRow[];
    data: IFormData;
}

// TODO: Partial interface
interface IPopupProposal {
    id: number;
    fundingRequest: number;
    name: string;
    investorInfo: any;
    isPublished: boolean;
    isScreening: boolean;
    owner: IUser;
    pools: IPool[];
    promotedFromScreening: boolean;
    valueProposition: string;
    extendedInfo: IExtendedInfo;
}

export interface IViewAllScreeningsComponentProps {
    popupProposal?: IPopupProposal;
    dismissPopup: () => void;
}

export interface IViewAllScreeningsComponentState {
}

export class ViewAllScreeningsComponent extends React.Component<IViewAllScreeningsComponentProps, IViewAllScreeningsComponentState> {
    constructor(props: IViewAllScreeningsComponentProps) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <Modal
                isOpen={!!this.props.popupProposal}
                onDismiss={() => {this.props.dismissPopup();}}
                isBlocking={false}
                styles={{
                    main: {
                        maxHeight: "80%",
                    },
                    scrollableContent: {
                        padding: "10px",
                    },
                }}
                >
                {this.props.popupProposal &&
                    <DynamicForm
                        schemaRows={this.props.popupProposal.extendedInfo.schema}
                        data={this.props.popupProposal.extendedInfo.data}
                        onDataChange={(fieldUuid, newValue) => {}}
                        onValidationChange={(fieldUuid, valid) => {}}
                        readOnly={true}
                        />}
            </Modal>
        );
    }
}