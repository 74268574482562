import { isString } from "util";
import * as UUID from "uuid";

import { fieldSizeToColumns } from "./FieldSize";
import { IFieldSchema } from "./IFieldSchema";
import { validateFieldSchema } from "./validateFieldSchema";

export class FormSchemaRow {
    public uuid: Readonly<string>;
    public fields: IFieldSchema[];

    constructor(fields: IFieldSchema[], uuid?: string) {
        this.uuid = (uuid) ? uuid : UUID.v4();
        this.fields = fields;
    }

    public getField(fieldUuid: string): IFieldSchema | undefined {
        return this.fields.find((checkingFieldSchema: IFieldSchema) => checkingFieldSchema.uuid === fieldUuid);
    }

    public getFieldIndex(fieldUuid: string): number {
        return this.fields.findIndex((checkingFieldSchema: IFieldSchema) => checkingFieldSchema.uuid === fieldUuid);
    }

    public getColumnsOccupied(): number {
        let cellsOccupied = 0;
        this.fields.forEach((field: IFieldSchema) => {
            cellsOccupied += fieldSizeToColumns(field.fieldSize);
        });
        return cellsOccupied;
    }

    public isFull(): boolean {
        return (this.getColumnsOccupied() >= 12);
    }

    static isFieldsArray(fields: any[]): boolean {
        if (!Array.isArray(fields)) {
            return false;
        }

        let isFieldsArray = true;

        fields.forEach((field: any) => {
            if (!validateFieldSchema(field)) {
                isFieldsArray = false;
            }
        });

        return isFieldsArray;
    }

    static parseRows(input: any[]): FormSchemaRow[] {
        let formRows: FormSchemaRow[] = [];

        if (Array.isArray(input)) {
            input.forEach((row: any) => {
                const hasUuid = ((row["uuid"] !== undefined) && isString(row.uuid));
                const hasFields = ((row["fields"] !== undefined) && FormSchemaRow.isFieldsArray(row.fields));

                if (hasUuid && hasFields) {
                    formRows.push(new FormSchemaRow(row.fields, row.uuid));
                }
            });
        }

        return formRows;
    }
}