import { extend } from "../../../../utils/extend";

import { FieldType } from "./FieldType";
import { CreateNewFieldSchema, IFieldSchema } from "./IFieldSchema";

export interface IInstructionsFieldSchema extends IFieldSchema {
    
}

export function CreateNewInstructionsFieldSchema(): IInstructionsFieldSchema {
    let newSchema = extend(CreateNewFieldSchema(FieldType.Instructions), {
        descriptionVisible: true,
    });

    return newSchema;
}