import { customizable, memoizeFunction, mergeStyleSets, IStyle, ITheme } from "office-ui-fabric-react";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import { ChoiceGroup, IChoiceGroupOption } from "office-ui-fabric-react/lib/ChoiceGroup";
import { Label } from "office-ui-fabric-react/lib/Label";
import * as React from "react";

import { InfoButtonCallout } from "../../../components/InfoButtonCallout";
import { ErrorMessage } from "../../ErrorMessage";

import { IFieldProps } from "./IFieldProps";
import { IMultipleChoiceFieldOptionSchema } from "./formschema/IMultipleChoiceFieldOptionSchema";
import { IMultipleChoiceFieldSchema } from "./formschema/IMultipleChoiceFieldSchema";

export interface IMultipleChoiceStyles {
    root?: IStyle;
    checkboxWrapper?: IStyle;
    tooltipLabel?: IStyle;
}

export interface IMultipleChoiceClassNames {
    root: string;
    checkboxWrapper: string;
    tooltipLabel: string;
}

export interface IMultipleChoiceProps extends IFieldProps<IMultipleChoiceFieldSchema> {
    className?: string;
    styles?: IMultipleChoiceStyles;
    theme?: ITheme;
}

export const getClassNames = memoizeFunction(
    (theme: ITheme, styles: IMultipleChoiceStyles, className?: string): IMultipleChoiceClassNames => {
        return mergeStyleSets({
            root: [
                styles.root,
                className,
            ],
            checkboxWrapper: [
                {
                    marginTop: "8px",
                    minHeight: "26px",
                },
                styles.checkboxWrapper,
            ],
            tooltipLabel: [
                {
                    display: "flex",
                    alignItems: "center",
                },
                styles.tooltipLabel,
            ],
        });
    });

@customizable("MultipleChoice", ["theme", "styles"], true)
export class MultipleChoice extends React.Component<IMultipleChoiceProps> {
    private isChecked(optionUuid: string): boolean {
        return this.props.value ? (this.props.value.indexOf(optionUuid) !== -1) : false;
    }

    private checkOption(optionUuid: string, checked: boolean): void {
        let checkedOptions: string[] = this.props.value ? this.props.value.split(",") : [];

        const checkedIndex = checkedOptions.findIndex((value) => (value === optionUuid));

        if (checkedIndex === -1) {
            checkedOptions.push(optionUuid);
        } else {
            checkedOptions.splice(checkedIndex, 1);
        }

        const newValue = checkedOptions.join(",");

        this.props.onValueChange(this.props.schema.uuid, newValue);
        this.props.onValidationChange(this.props.schema.uuid, !this.props.schema.required || (newValue !== ""));
    }

    render() {
        const classNames = getClassNames(this.props.theme!, this.props.styles!, this.props.className);

        const choiceGroupOptions = this.props.schema.options.map((option: IMultipleChoiceFieldOptionSchema, index: number): IChoiceGroupOption => {
            return {
                key: option.uuid,
                text: option.text,
            };
        });

        const label = <Label
            id={`labelFor${this.props.schema.uuid}`}
            required={this.props.schema.required}
            >
            {this.props.schema.titleText}
        </Label>;

        const errorMessage = (this.props.schema.required && (this.props.value === "")) ? "This field is required." : undefined;

        return (
            <div className={classNames.root}>
                {this.props.schema.allowMultiple && 
                    <div>
                        {this.props.schema.tooltipVisible && 
                            <div className={classNames.tooltipLabel}>
                                <Label required={this.props.schema.required}>
                                    {this.props.schema.titleText}
                                </Label>
                                <InfoButtonCallout>
                                    {this.props.schema.tooltipText}
                                </InfoButtonCallout>
                            </div>}
                        {!this.props.schema.tooltipVisible && (this.props.schema.titleText.length > 0) &&
                            <Label required={this.props.schema.required}>
                                {this.props.schema.titleText}
                            </Label>
                        }
                        {this.props.schema.options.map((option, index) => (
                            <div key={index} className={classNames.checkboxWrapper}>
                                <Checkbox
                                    label={option.text}
                                    checked={this.isChecked(option.uuid)}
                                    onChange={(ev, checked) => {this.checkOption(option.uuid, checked === true);}}
                                    disabled={this.props.readOnly}
                                    />
                            </div>
                        ))}
                    </div>
                }
                {!this.props.schema.allowMultiple && 
                    <>
                        {!this.props.schema.tooltipVisible && label}
                        {this.props.schema.tooltipVisible && 
                            <div className={classNames.tooltipLabel}>
                                {label}
                                <InfoButtonCallout>
                                    {this.props.schema.tooltipText}
                                </InfoButtonCallout>
                            </div>}
                        <ChoiceGroup
                            ariaLabelledBy={`labelFor${this.props.schema.uuid}`}
                            required={this.props.schema.required}
                            options={choiceGroupOptions}
                            selectedKey={(this.props.value !== undefined) ? this.props.value : ""}
                            onChange={(ev, choice) => { this.props.onValueChange(this.props.schema.uuid, choice!.key); this.props.onValidationChange(this.props.schema.uuid, true); }}
                            disabled={this.props.readOnly}
                            />
                    </>
                }
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                {this.props.schema.descriptionVisible && <p>{this.props.schema.descriptionText}</p>}
            </div>
        );
    }

    private static findOptionKey(props: IMultipleChoiceFieldOptionSchema[], value: string): string {
        return `${props.findIndex((current: IMultipleChoiceFieldOptionSchema) => (current.text === value))}`;
    }
}