import * as UUID from "uuid";

import { FieldSize } from "./FieldSize";
import { FieldType } from "./FieldType";

export interface IFieldSchema {
    type: FieldType;
    uuid: string;
    fieldSize: FieldSize;
    required: boolean;
    titleText: string;
    tooltipVisible: boolean;
    tooltipText: string;
    descriptionVisible: boolean;
    descriptionText: string;
    locked?: boolean;
}

export function CreateNewFieldSchema(type: FieldType): IFieldSchema {
    return {
        type: type,
        uuid: UUID.v4(),
        fieldSize: FieldSize.Full,
        titleText: "",
        descriptionVisible: false,
        descriptionText: "",
        tooltipVisible: false,
        tooltipText: "",
        required: false,
    };
}