import { fieldSizes } from "./FieldSize";
import { fieldTypes, FieldType } from "./FieldType";
import { IFieldSchema } from "./IFieldSchema";
import { IMultipleChoiceFieldSchema } from "./IMultipleChoiceFieldSchema";
import { knownTypes } from "./ITextFieldSchema";

function verifyFieldType(fieldName: string, object: any, type: string, optional: boolean = false): boolean {
    if (object[fieldName] !== undefined) {
        return (type === "array") ? Array.isArray(object[fieldName]) : (typeof object[fieldName] === type);
    } else {
        return optional;
    }
}

function verifyEnumType<T>(fieldName: string, object: any, values: T[]): boolean {
    let hasField = false;
    
    if (object[fieldName] !== undefined) {
        values.forEach((value: T) => {
            if (object[fieldName] === value) {
                hasField = true;
            }
        });
    }

    return hasField;
}

function verifyOptionsArray(options: any[]): boolean {
    let allOptionsValid = true;

    options.forEach((option: any) => {
        const optionValid = verifyFieldType("text", option, "string");
        const uuidValid = verifyFieldType("uuid", option, "string");

        if (!optionValid || !uuidValid) {
            allOptionsValid = false;
        }
    });

    return allOptionsValid;
}

function verifyFieldSubtypes(fieldSchema: IFieldSchema): boolean {
    switch(fieldSchema.type) {
        case FieldType.MultipleChoice:
            return (verifyFieldType("options", fieldSchema, "array") &&
                verifyOptionsArray((fieldSchema as IMultipleChoiceFieldSchema).options) &&
                verifyFieldType("allowMultiple", fieldSchema, "boolean"));
        case FieldType.Text:
            return (verifyFieldType("placeholderText", fieldSchema, "string") &&
                verifyFieldType("iconName", fieldSchema, "string", true) &&
                verifyFieldType("multiline", fieldSchema, "boolean") &&
                verifyEnumType("knownType", fieldSchema, knownTypes()) &&
                verifyFieldType("characterLimit", fieldSchema, "number", true));
        // None of the following have their own unique fields
        case FieldType.Header:
            return true;
        case FieldType.Captcha:
            return true;
        case FieldType.AcceptTerms:
            return true;
        case FieldType.Instructions:
            return true;
    }
}

export function validateFieldSchema(fieldSchema: any): boolean {
    return (verifyEnumType("type", fieldSchema, fieldTypes()) &&
        verifyFieldType("uuid", fieldSchema, "string") &&
        verifyEnumType("fieldSize", fieldSchema, fieldSizes()) &&
        verifyFieldType("required", fieldSchema, "boolean") &&
        verifyFieldType("tooltipVisible", fieldSchema, "boolean") &&
        verifyFieldType("tooltipText", fieldSchema, "string") &&
        verifyFieldType("descriptionVisible", fieldSchema, "boolean") &&
        verifyFieldType("descriptionText", fieldSchema, "string") &&
        verifyFieldSubtypes(fieldSchema as IFieldSchema) &&
        verifyFieldType("locked", fieldSchema, "boolean", true));
}