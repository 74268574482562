import { customizable, memoizeFunction, mergeStyleSets, ICommandBarItemProps, ITheme } from "office-ui-fabric-react";
import * as React from "react";

import { FieldEditor } from "./FieldEditor";
import { areFieldEditorPropsEqual, IFieldEditorProps } from "./IFieldEditorProps";
import { IAcceptTermsFieldSchema } from "./formschema/IAcceptTermsFieldSchema";

export interface IAcceptTermsFormFieldEditorStyles {
}

export interface IAcceptTermsFormFieldEditorClassNames {
}

export interface IAcceptTermsFormFieldEditorProps extends IFieldEditorProps<IAcceptTermsFieldSchema> {
    styles?: IAcceptTermsFormFieldEditorStyles;
    theme?: ITheme;
}

export const getClassNames = memoizeFunction(
    (theme: ITheme, styles: IAcceptTermsFormFieldEditorStyles, className?: string): IAcceptTermsFormFieldEditorClassNames => {
        return mergeStyleSets({
        });
    });

@customizable("AcceptTermsFormFieldEditor", ["theme", "styles"], true)
export class AcceptTermsFormFieldEditor extends React.Component<IAcceptTermsFormFieldEditorProps> {
    shouldComponentUpdate(nextProps: IAcceptTermsFormFieldEditorProps) {
        return !areFieldEditorPropsEqual(this.props, nextProps);
    }

    render() {
        const classNames = getClassNames(this.props.theme!, this.props.styles!);

        let footerItems: ICommandBarItemProps[] = [
        ];
        return (
            <FieldEditor<IAcceptTermsFieldSchema>
                schema={this.props.schema}
                onSchemaChange={this.props.onSchemaChange}
                columnsOccupiedInRow={this.props.columnsOccupiedInRow}
                footerItems={footerItems}
                excludeDefaultFooterItems={true}
                allowTooltip={true}
                >
            </FieldEditor>
        );
    }
}