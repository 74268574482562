import update from "immutability-helper";
import { customizable, memoizeFunction, mergeStyleSets, IStyle, ITheme } from "office-ui-fabric-react";
import { CommandBar, ICommandBarItemProps } from "office-ui-fabric-react/lib/CommandBar";
import { IContextualMenuItem } from "office-ui-fabric-react/lib/ContextualMenu";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import * as React from "react";

import { ToggleCommandBarButton } from "../../ToggleCommandBarButton";

import { IFieldEditorProps } from "./IFieldEditorProps";
import { fieldSizes, fieldSizeToColumns, fieldSizeToString, FieldSize } from "./formschema/FieldSize";
import { IFieldSchema } from "./formschema/IFieldSchema";

export interface IFieldEditorStyles {
    root?: IStyle;
    titleInput?: IStyle;
    description?: IStyle;
    tooltip?: IStyle;
    descriptionInput?: IStyle;
    tooltipInput?: IStyle;
    field?: IStyle;
    footer?: IStyle;
    footerCommandBar?: IStyle;
}

export interface IFieldEditorClassNames {
    root: string;
    titleInput: string;
    description: string;
    tooltip: string;
    descriptionInput: string;
    tooltipInput: string;
    field: string;
    footer: string;
    footerCommandBar: string;
}

export interface IFieldEditProps<SchemaType extends IFieldSchema> extends IFieldEditorProps<SchemaType> {
    footerItems?: ICommandBarItemProps[];
    footerOverflowItems?: ICommandBarItemProps[];
    footerFarItems?: ICommandBarItemProps[];
    excludeDefaultFooterItems?: boolean;
    allowTitle?: boolean;
    allowRequired?: boolean;
    allowDescription?: boolean;
    allowSize?: boolean;
    allowTooltip?: boolean;
    allowRemove?: boolean;
    className?: string;
    styles?: IFieldEditorStyles;
    theme?: ITheme;
}

export const getClassNames = memoizeFunction(
    (theme: ITheme, styles: IFieldEditorStyles, className?: string): IFieldEditorClassNames => {
        const fieldEditPadding = 20;
        const fieldSpacing = 4;
        return mergeStyleSets({
            root: [
                {
                    backgroundColor: theme.palette.neutralLighterAlt,
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: theme.palette.neutralQuaternary,
                    padding: `${fieldEditPadding}px ${fieldEditPadding}px 0 ${fieldEditPadding}px`,
                } as IStyle,
                styles.root,
                className,
            ],
            titleInput: [
                {
                    width: "100%",
                    marginTop: `${fieldSpacing}px`,
                },
                styles.titleInput,
            ],
            description: [
                {
                    marginTop: `${fieldSpacing}px`,
                },
                styles.description,
            ],
            tooltip: [
                {
                    marginTop: `${fieldSpacing}px`,
                },
                styles.tooltip,
            ],
            descriptionInput: [
                {
                    width: "100%",
                },
                styles.descriptionInput,
            ],
            tooltipInput: [
                {
                    width: "100%",
                },
                styles.tooltipInput,
            ],
            field: [
                {
                    marginTop: `${fieldSpacing}px`,
                },
                styles.field,
            ],
            footer: [
                {
                    margin: `0 ${-1*fieldEditPadding}px`,
                    marginTop: `${fieldEditPadding}px`,
                    borderTopWidth: "1px",
                    borderTopStyle: "solid",
                    borderTopColor: theme.palette.neutralQuaternary,
                },
                styles.footer,
            ],
            footerCommandBar: [
                {
                    padding: "0",
                },
                styles.footerCommandBar,
            ],
        });
    });

@customizable("FormHeader", ["theme", "styles"], true)
export class FieldEditor<SchemaType extends IFieldSchema> extends React.Component<IFieldEditProps<SchemaType>> {
    render() {
        const {
            footerItems = [],
            footerOverflowItems = [],
            footerFarItems = [],
            allowTitle = true,
            allowRequired = (this.props.schema.locked !== true) && (this.props.excludeDefaultFooterItems !== true),
            allowDescription = (this.props.excludeDefaultFooterItems !== true),
            allowSize = (this.props.excludeDefaultFooterItems !== true),
            allowTooltip = (this.props.excludeDefaultFooterItems !== true),
            allowRemove = (this.props.schema.locked !== true),
        } = this.props;

        const classNames = getClassNames(this.props.theme!, this.props.styles!, this.props.className);

        const mergedFooterItems = [...footerItems];

        if (allowRequired) {
            mergedFooterItems.push({
                key: "required",
                name: "Required",
                iconProps: {iconName: "fa-asterisk"},
                canCheck: true,
                checked: this.props.schema.required,
                onClick: () => this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {required: {$set: !this.props.schema.required}})),
                commandBarButtonAs: ToggleCommandBarButton,
            });
        }

        if (allowDescription) {
            mergedFooterItems.push({
                key: "description",
                name: "Description",
                iconProps: {iconName: "fa-align-left"},
                canCheck: true,
                checked: this.props.schema.descriptionVisible,
                onClick: () => this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {descriptionVisible: {$set: !this.props.schema.descriptionVisible}})),
                commandBarButtonAs: ToggleCommandBarButton,
            });
        }

        if (allowSize) {
            mergedFooterItems.push({
                key: "size",
                name: "Size",
                iconProps: {iconName: "fa-expand"},
                canCheck: false,
                subMenuProps: {
                    items: fieldSizes().map((fieldSize: FieldSize): IContextualMenuItem => {
                        return {
                            key: fieldSizeToString(fieldSize),
                            text: fieldSizeToString(fieldSize),
                            disabled: (this.props.columnsOccupiedInRow-fieldSizeToColumns(this.props.schema.fieldSize)+fieldSizeToColumns(fieldSize) > 12),
                            canCheck: true,
                            checked: (this.props.schema.fieldSize === fieldSize),
                            onClick: () => this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {fieldSize: {$set: fieldSize}})),
                        };
                    }),
                },
            });
        }

        if (allowTooltip) {
            mergedFooterItems.push({
                key: "tooltip",
                name: "Tooltip",
                iconProps: {iconName: "fa-comment-o"},
                canCheck: true,
                checked: this.props.schema.tooltipVisible,
                onClick: () => this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {tooltipVisible: {$set: !this.props.schema.tooltipVisible}})),
                commandBarButtonAs: ToggleCommandBarButton,
            });
        }

        var mergedFarFooterItems = [...footerFarItems];
        
        if (allowRemove) {
            mergedFarFooterItems.push({
                key: "remove",
                name: "Remove field",
                iconProps: {iconName: "fa-trash"},
                iconOnly: true,
                onClick: () => this.props.onSchemaChange(this.props.schema.uuid, null),
            });
        }

        return (
            <div className={classNames.root}>
                <div>
                    {allowTitle && <TextField
                        className={`${classNames.titleInput} FormEditor-nodrag`}
                        placeholder="Field title"
                        ariaLabel="Enter field title here"
                        value={this.props.schema.titleText}
                        required={this.props.schema.required}
                        onChange={(event, newValue) => this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {titleText: {$set: newValue}}))}
                        />}
                </div>
                <div className={classNames.description}>
                {this.props.schema.descriptionVisible && <TextField
                        className={`${classNames.descriptionInput} FormEditor-nodrag`}
                        placeholder="Description"
                        ariaLabel="Enter field description here"
                        value={this.props.schema.descriptionText}
                        onChange={(event, newValue) => this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {descriptionText: {$set: newValue}}))}
                        multiline
                        autoAdjustHeight
                        />}
                </div>
                <div className={classNames.tooltip}>
                {this.props.schema.tooltipVisible && <TextField
                        className={`${classNames.tooltipInput} FormEditor-nodrag`}
                        placeholder="Tooltip"
                        ariaLabel="Enter field tooltip here"
                        value={this.props.schema.tooltipText}
                        onChange={(event, newValue) => this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {tooltipText: {$set: newValue}}))}
                        multiline
                        autoAdjustHeight
                        />}
                </div>
                <div className={classNames.field}>{this.props.children}</div>
                <div className={classNames.footer}>
                    <CommandBar
                        className={classNames.footerCommandBar}
                        items={mergedFooterItems}
                        overflowItems={footerOverflowItems}
                        farItems={mergedFarFooterItems}
                        />
                </div>
            </div>
        );
    }
}