import update from "immutability-helper";
import { customizable, memoizeFunction, mergeStyleSets, IStyle, ITheme } from "office-ui-fabric-react";
import { ActionButton, IconButton } from "office-ui-fabric-react/lib/Button";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import { ChoiceGroup, IChoiceGroupOption } from "office-ui-fabric-react/lib/ChoiceGroup";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import * as React from "react";

import { ToggleCommandBarButton } from "../../../components/ToggleCommandBarButton";

import { FieldEditor } from "./FieldEditor";
import { areFieldEditorPropsEqual, IFieldEditorProps } from "./IFieldEditorProps";
import { CreateNewMultipleChoiceFieldOptionSchema, IMultipleChoiceFieldOptionSchema } from "./formschema/IMultipleChoiceFieldOptionSchema";
import { IMultipleChoiceFieldSchema } from "./formschema/IMultipleChoiceFieldSchema";

export interface IMultipleChoiceFormFieldEditorStyles {
    root?: IStyle;
    choiceWrapper?: IStyle;
    choiceWrapperMultiple?: IStyle;
    checkbox?: IStyle;
    choiceTextField?: IStyle;
    choiceField?: IStyle;
    choiceFieldWrapper?: IStyle;
}

export interface IMultipleChoiceFormFieldEditorClassNames {
    root: string;
    choiceWrapper: string;
    choiceWrapperMultiple: string;
    checkbox: string;
    choiceTextField: string;
    choiceFieldWrapper: string;
}

export interface IMultipleChoiceFormFieldEditorProps extends IFieldEditorProps<IMultipleChoiceFieldSchema> {
    className?: string;
    styles?: IMultipleChoiceFormFieldEditorStyles;
    theme?: ITheme;
}

export const getClassNames = memoizeFunction(
    (theme: ITheme, styles: IMultipleChoiceFormFieldEditorStyles, className?: string): IMultipleChoiceFormFieldEditorClassNames => {
        return mergeStyleSets({
            root: [
                styles.root,
                className,
            ],
            choiceWrapper: [
                {
                    height: "32px",
                },
                styles.choiceWrapper,
            ],
            choiceWrapperMultiple: [
                {
                    height: "32px",
                    marginTop: "8px",
                },
                styles.choiceWrapperMultiple,
            ],
            checkbox: [
                {
                    display: "inline-block",
                    marginRight: "6px",
                    marginTop: "6px",
                    marginBottom: "6px",
                },
                styles.checkbox,
            ],
            choiceTextField: [
                {
                    display: "inline-block",
                    width: "calc(100% - 58px)",
                },
                styles.choiceTextField,
            ],
            choiceField: [
                {
                    marginTop: "6px",
                    marginBottom: "6px",
                },
                styles.choiceField,
            ],
            choiceFieldWrapper: [
                {
                    width: "100%",
                },
                styles.choiceFieldWrapper,
            ],
        });
    });

@customizable("MultipleChoiceFormFieldEditor", ["theme", "styles"], true)
export class MultipleChoiceFormFieldEditor extends React.Component<IMultipleChoiceFormFieldEditorProps> {
    private onRemoveOptionClick = (index: number) => {
        this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {options: {$splice: [[index, 1]]}}));
    }

    private onAddOptionClick = () => {
        this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {options: {$push: [CreateNewMultipleChoiceFieldOptionSchema()]}}));
    }

    private onOptionChange = (index: number, newValue: string | undefined) => {
        const newValueDefined: string = (!!newValue) ? newValue : "";
        this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {options: {[index]: {text: {$set: newValue}}}}));
    }

    shouldComponentUpdate(nextProps: IMultipleChoiceFormFieldEditorProps) {
        return !areFieldEditorPropsEqual(this.props, nextProps);
    }

    render() {
        const classNames = getClassNames(this.props.theme!, this.props.styles!, this.props.className);

        const choiceGroupOptions = this.props.schema.options.map((option: IMultipleChoiceFieldOptionSchema, index: number): IChoiceGroupOption => {
            return {
                key: `${index}`,
                text: "",
                checked: false,
                disabled: true,
                onRenderField: (props?: IChoiceGroupOption, defaultRender?: (props?: IChoiceGroupOption) => JSX.Element | null): JSX.Element => {
                    return (
                        <div
                            className={this.props.schema.allowMultiple ? classNames.choiceWrapperMultiple : classNames.choiceWrapper}
                            key={props!.key}
                            >
                            {defaultRender!(props)}
                            <TextField
                                value={option.text}
                                className={`${classNames.choiceTextField} FormEditor-nodrag`}
                                placeholder="Enter a label for this option"
                                onChange={(event, newValue) => this.onOptionChange(index, newValue)}
                                />
                            <IconButton
                                iconProps={{iconName: "fa-trash"}}
                                title="Remove option"
                                onClick={() => this.onRemoveOptionClick(index)}
                                />
                        </div>
                    );
                },
            };
        });

        return (
            <FieldEditor<IMultipleChoiceFieldSchema>
                schema={this.props.schema}
                onSchemaChange={this.props.onSchemaChange}
                columnsOccupiedInRow={this.props.columnsOccupiedInRow}
                footerItems={[
                    {
                        key: "allowmultiple",
                        name: "Allow multiple",
                        canCheck: true,
                        checked: this.props.schema.allowMultiple,
                        onClick: () => this.props.onSchemaChange(this.props.schema.uuid, update(this.props.schema, {allowMultiple: {$set: !this.props.schema.allowMultiple}})),
                        commandBarButtonAs: ToggleCommandBarButton,
                    },
                ]}
                >
                {this.props.schema.allowMultiple &&
                    <div>
                        {choiceGroupOptions.map((option) => {
                            return option.onRenderField!(option, () => <Checkbox checked={false} className={classNames.checkbox} />);
                        })}
                    </div>
                }
                {!this.props.schema.allowMultiple && 
                    <ChoiceGroup
                        selectedKey=""
                        options={choiceGroupOptions}
                        disabled={true}
                        styles={{
                            
                        }}
                        />
                }
                <ActionButton
                    text="Add Option"
                    iconProps={{iconName: "fa-plus"}}
                    onClick={this.onAddOptionClick} />
            </FieldEditor>
        );
    }
}