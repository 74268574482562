import { registerIcons, registerIconAlias } from "office-ui-fabric-react";

export const fontAwesomeIcons = {
    "fa-glass":"\uF000",
    "fa-music":"\uF001",
    "fa-search":"\uF002",
    "fa-envelope-o":"\uF003",
    "fa-heart":"\uF004",
    "fa-star":"\uF005",
    "fa-star-o":"\uF006",
    "fa-user":"\uF007",
    "fa-film":"\uF008",
    "fa-th-large":"\uF009",
    "fa-th":"\uF00a",
    "fa-th-list":"\uF00b",
    "fa-check":"\uF00c",
    "fa-times":"\uF00d",
    "fa-search-plus":"\uF00e",
    "fa-search-minus":"\uF010",
    "fa-power-off":"\uF011",
    "fa-signal":"\uF012",
    "fa-gear":"\uF013",
    "fa-trash-o":"\uF014",
    "fa-home":"\uF015",
    "fa-file-o":"\uF016",
    "fa-clock-o":"\uF017",
    "fa-road":"\uF018",
    "fa-download":"\uF019",
    "fa-arrow-circle-o-down":"\uF01a",
    "fa-arrow-circle-o-up":"\uF01b",
    "fa-inbox":"\uF01c",
    "fa-play-circle-o":"\uF01d",
    "fa-rotate-right":"\uF01e",
    "fa-refresh":"\uF021",
    "fa-list-alt":"\uF022",
    "fa-lock":"\uF023",
    "fa-flag":"\uF024",
    "fa-headphones":"\uF025",
    "fa-volume-off":"\uF026",
    "fa-volume-down":"\uF027",
    "fa-volume-up":"\uF028",
    "fa-qrcode":"\uF029",
    "fa-barcode":"\uF02a",
    "fa-tag":"\uF02b",
    "fa-tags":"\uF02c",
    "fa-book":"\uF02d",
    "fa-bookmark":"\uF02e",
    "fa-print":"\uF02f",
    "fa-camera":"\uF030",
    "fa-font":"\uF031",
    "fa-bold":"\uF032",
    "fa-italic":"\uF033",
    "fa-text-height":"\uF034",
    "fa-text-width":"\uF035",
    "fa-align-left":"\uF036",
    "fa-align-center":"\uF037",
    "fa-align-right":"\uF038",
    "fa-align-justify":"\uF039",
    "fa-list":"\uF03a",
    "fa-outdent":"\uF03b",
    "fa-indent":"\uF03c",
    "fa-video-camera":"\uF03d",
    "fa-picture-o":"\uF03e",
    "fa-pencil":"\uF040",
    "fa-map-marker":"\uF041",
    "fa-adjust":"\uF042",
    "fa-tint":"\uF043",
    "fa-pencil-square-o":"\uF044",
    "fa-share-square-o":"\uF045",
    "fa-check-square-o":"\uF046",
    "fa-arrows":"\uF047",
    "fa-step-backward":"\uF048",
    "fa-fast-backward":"\uF049",
    "fa-backward":"\uF04a",
    "fa-play":"\uF04b",
    "fa-pause":"\uF04c",
    "fa-stop":"\uF04d",
    "fa-forward":"\uF04e",
    "fa-fast-forward":"\uF050",
    "fa-step-forward":"\uF051",
    "fa-eject":"\uF052",
    "fa-chevron-left":"\uF053",
    "fa-chevron-right":"\uF054",
    "fa-plus-circle":"\uF055",
    "fa-minus-circle":"\uF056",
    "fa-times-circle":"\uF057",
    "fa-check-circle":"\uF058",
    "fa-question-circle":"\uF059",
    "fa-info-circle":"\uF05a",
    "fa-crosshairs":"\uF05b",
    "fa-times-circle-o":"\uF05c",
    "fa-check-circle-o":"\uF05d",
    "fa-ban":"\uF05e",
    "fa-arrow-left":"\uF060",
    "fa-arrow-right":"\uF061",
    "fa-arrow-up":"\uF062",
    "fa-arrow-down":"\uF063",
    "fa-share":"\uF064",
    "fa-expand":"\uF065",
    "fa-compress":"\uF066",
    "fa-plus":"\uF067",
    "fa-minus":"\uF068",
    "fa-asterisk":"\uF069",
    "fa-exclamation-circle":"\uF06a",
    "fa-gift":"\uF06b",
    "fa-leaf":"\uF06c",
    "fa-fire":"\uF06d",
    "fa-eye":"\uF06e",
    "fa-eye-slash":"\uF070",
    "fa-warning":"\uF071",
    "fa-plane":"\uF072",
    "fa-calendar":"\uF073",
    "fa-random":"\uF074",
    "fa-comment":"\uF075",
    "fa-magnet":"\uF076",
    "fa-chevron-up":"\uF077",
    "fa-chevron-down":"\uF078",
    "fa-retweet":"\uF079",
    "fa-shopping-cart":"\uF07a",
    "fa-folder":"\uF07b",
    "fa-folder-open":"\uF07c",
    "fa-arrows-v":"\uF07d",
    "fa-arrows-h":"\uF07e",
    "fa-bar-chart":"\uF080",
    "fa-twitter-square":"\uF081",
    "fa-facebook-square":"\uF082",
    "fa-camera-retro":"\uF083",
    "fa-key":"\uF084",
    "fa-gears":"\uF085",
    "fa-comments":"\uF086",
    "fa-thumbs-o-up":"\uF087",
    "fa-thumbs-o-down":"\uF088",
    "fa-star-half":"\uF089",
    "fa-heart-o":"\uF08a",
    "fa-sign-out":"\uF08b",
    "fa-linkedin-square":"\uF08c",
    "fa-thumb-tack":"\uF08d",
    "fa-external-link":"\uF08e",
    "fa-sign-in":"\uF090",
    "fa-trophy":"\uF091",
    "fa-github-square":"\uF092",
    "fa-upload":"\uF093",
    "fa-lemon-o":"\uF094",
    "fa-phone":"\uF095",
    "fa-square-o":"\uF096",
    "fa-bookmark-o":"\uF097",
    "fa-phone-square":"\uF098",
    "fa-twitter":"\uF099",
    "fa-facebook":"\uF09a",
    "fa-github":"\uF09b",
    "fa-unlock":"\uF09c",
    "fa-credit-card":"\uF09d",
    "fa-rss":"\uF09e",
    "fa-hdd-o":"\uF0a0",
    "fa-bullhorn":"\uF0a1",
    "fa-bell":"\uF0f3",
    "fa-certificate":"\uF0a3",
    "fa-hand-o-right":"\uF0a4",
    "fa-hand-o-left":"\uF0a5",
    "fa-hand-o-up":"\uF0a6",
    "fa-hand-o-down":"\uF0a7",
    "fa-arrow-circle-left":"\uF0a8",
    "fa-arrow-circle-right":"\uF0a9",
    "fa-arrow-circle-up":"\uF0aa",
    "fa-arrow-circle-down":"\uF0ab",
    "fa-globe":"\uF0ac",
    "fa-wrench":"\uF0ad",
    "fa-tasks":"\uF0ae",
    "fa-filter":"\uF0b0",
    "fa-briefcase":"\uF0b1",
    "fa-arrows-alt":"\uF0b2",
    "fa-users":"\uF0c0",
    "fa-link":"\uF0c1",
    "fa-cloud":"\uF0c2",
    "fa-flask":"\uF0c3",
    "fa-scissors":"\uF0c4",
    "fa-files-o":"\uF0c5",
    "fa-paperclip":"\uF0c6",
    "fa-save":"\uF0c7",
    "fa-square":"\uF0c8",
    "fa-reorder":"\uF0c9",
    "fa-list-ul":"\uF0ca",
    "fa-list-ol":"\uF0cb",
    "fa-strikethrough":"\uF0cc",
    "fa-underline":"\uF0cd",
    "fa-table":"\uF0ce",
    "fa-magic":"\uF0d0",
    "fa-truck":"\uF0d1",
    "fa-pinterest":"\uF0d2",
    "fa-pinterest-square":"\uF0d3",
    "fa-google-plus-square":"\uF0d4",
    "fa-google-plus":"\uF0d5",
    "fa-money":"\uF0d6",
    "fa-caret-down":"\uF0d7",
    "fa-caret-up":"\uF0d8",
    "fa-caret-left":"\uF0d9",
    "fa-caret-right":"\uF0da",
    "fa-columns":"\uF0db",
    "fa-unsorted":"\uF0dc",
    "fa-sort-down":"\uF0dd",
    "fa-sort-up":"\uF0de",
    "fa-envelope":"\uF0e0",
    "fa-linkedin":"\uF0e1",
    "fa-undo":"\uF0e2",
    "fa-legal":"\uF0e3",
    "fa-tachometer":"\uF0e4",
    "fa-comment-o":"\uF0e5",
    "fa-comments-o":"\uF0e6",
    "fa-flash":"\uF0e7",
    "fa-sitemap":"\uF0e8",
    "fa-umbrella":"\uF0e9",
    "fa-paste":"\uF0ea",
    "fa-lightbulb-o":"\uF0eb",
    "fa-exchange":"\uF0ec",
    "fa-cloud-download":"\uF0ed",
    "fa-cloud-upload":"\uF0ee",
    "fa-user-md":"\uF0f0",
    "fa-stethoscope":"\uF0f1",
    "fa-suitcase":"\uF0f2",
    "fa-bell-o":"\uF0a2",
    "fa-coffee":"\uF0f4",
    "fa-cutlery":"\uF0f5",
    "fa-file-text-o":"\uF0f6",
    "fa-building-o":"\uF0f7",
    "fa-hospital-o":"\uF0f8",
    "fa-ambulance":"\uF0f9",
    "fa-medkit":"\uF0fa",
    "fa-fighter-jet":"\uF0fb",
    "fa-beer":"\uF0fc",
    "fa-h-square":"\uF0fd",
    "fa-plus-square":"\uF0fe",
    "fa-angle-double-left":"\uF100",
    "fa-angle-double-right":"\uF101",
    "fa-angle-double-up":"\uF102",
    "fa-angle-double-down":"\uF103",
    "fa-angle-left":"\uF104",
    "fa-angle-right":"\uF105",
    "fa-angle-up":"\uF106",
    "fa-angle-down":"\uF107",
    "fa-desktop":"\uF108",
    "fa-laptop":"\uF109",
    "fa-tablet":"\uF10a",
    "fa-mobile":"\uF10b",
    "fa-circle-o":"\uF10c",
    "fa-quote-left":"\uF10d",
    "fa-quote-right":"\uF10e",
    "fa-spinner":"\uF110",
    "fa-circle":"\uF111",
    "fa-reply":"\uF112",
    "fa-github-alt":"\uF113",
    "fa-folder-o":"\uF114",
    "fa-folder-open-o":"\uF115",
    "fa-smile-o":"\uF118",
    "fa-frown-o":"\uF119",
    "fa-meh-o":"\uF11a",
    "fa-gamepad":"\uF11b",
    "fa-keyboard-o":"\uF11c",
    "fa-flag-o":"\uF11d",
    "fa-flag-checkered":"\uF11e",
    "fa-terminal":"\uF120",
    "fa-code":"\uF121",
    "fa-reply-all":"\uF122",
    "fa-star-half-o":"\uF123",
    "fa-location-arrow":"\uF124",
    "fa-crop":"\uF125",
    "fa-code-fork":"\uF126",
    "fa-unlink":"\uF127",
    "fa-question":"\uF128",
    "fa-info":"\uF129",
    "fa-exclamation":"\uF12a",
    "fa-superscript":"\uF12b",
    "fa-subscript":"\uF12c",
    "fa-eraser":"\uF12d",
    "fa-puzzle-piece":"\uF12e",
    "fa-microphone":"\uF130",
    "fa-microphone-slash":"\uF131",
    "fa-shield":"\uF132",
    "fa-calendar-o":"\uF133",
    "fa-fire-extinguisher":"\uF134",
    "fa-rocket":"\uF135",
    "fa-maxcdn":"\uF136",
    "fa-chevron-circle-left":"\uF137",
    "fa-chevron-circle-right":"\uF138",
    "fa-chevron-circle-up":"\uF139",
    "fa-chevron-circle-down":"\uF13a",
    "fa-html5":"\uF13b",
    "fa-css3":"\uF13c",
    "fa-anchor":"\uF13d",
    "fa-unlock-alt":"\uF13e",
    "fa-bullseye":"\uF140",
    "fa-ellipsis-h":"\uF141",
    "fa-ellipsis-v":"\uF142",
    "fa-rss-square":"\uF143",
    "fa-play-circle":"\uF144",
    "fa-ticket":"\uF145",
    "fa-minus-square":"\uF146",
    "fa-minus-square-o":"\uF147",
    "fa-level-up":"\uF148",
    "fa-level-down":"\uF149",
    "fa-check-square":"\uF14a",
    "fa-pencil-square":"\uF14b",
    "fa-external-link-square":"\uF14c",
    "fa-share-square":"\uF14d",
    "fa-compass":"\uF14e",
    "fa-toggle-down":"\uF150",
    "fa-toggle-up":"\uF151",
    "fa-toggle-right":"\uF152",
    "fa-euro":"\uF153",
    "fa-gbp":"\uF154",
    "fa-usd":"\uF155",
    "fa-dollar":"\uF155",
    "fa-rupee":"\uF156",
    "fa-yen":"\uF157",
    "fa-ruble":"\uF158",
    "fa-won":"\uF159",
    "fa-btc":"\uF15a",
    "fa-file":"\uF15b",
    "fa-file-text":"\uF15c",
    "fa-sort-alpha-asc":"\uF15d",
    "fa-sort-alpha-desc":"\uF15e",
    "fa-sort-amount-asc":"\uF160",
    "fa-sort-amount-desc":"\uF161",
    "fa-sort-numeric-asc":"\uF162",
    "fa-sort-numeric-desc":"\uF163",
    "fa-thumbs-up":"\uF164",
    "fa-thumbs-down":"\uF165",
    "fa-youtube-square":"\uF166",
    "fa-youtube":"\uF167",
    "fa-xing":"\uF168",
    "fa-xing-square":"\uF169",
    "fa-youtube-play":"\uF16a",
    "fa-dropbox":"\uF16b",
    "fa-stack-overflow":"\uF16c",
    "fa-instagram":"\uF16d",
    "fa-flickr":"\uF16e",
    "fa-adn":"\uF170",
    "fa-bitbucket":"\uF171",
    "fa-bitbucket-square":"\uF172",
    "fa-tumblr":"\uF173",
    "fa-tumblr-square":"\uF174",
    "fa-long-arrow-down":"\uF175",
    "fa-long-arrow-up":"\uF176",
    "fa-long-arrow-left":"\uF177",
    "fa-long-arrow-right":"\uF178",
    "fa-apple":"\uF179",
    "fa-windows":"\uF17a",
    "fa-android":"\uF17b",
    "fa-linux":"\uF17c",
    "fa-dribbble":"\uF17d",
    "fa-skype":"\uF17e",
    "fa-foursquare":"\uF180",
    "fa-trello":"\uF181",
    "fa-female":"\uF182",
    "fa-male":"\uF183",
    "fa-gittip":"\uF184",
    "fa-sun-o":"\uF185",
    "fa-moon-o":"\uF186",
    "fa-archive":"\uF187",
    "fa-bug":"\uF188",
    "fa-vk":"\uF189",
    "fa-weibo":"\uF18a",
    "fa-renren":"\uF18b",
    "fa-pagelines":"\uF18c",
    "fa-stack-exchange":"\uF18d",
    "fa-arrow-circle-o-right":"\uF18e",
    "fa-arrow-circle-o-left":"\uF190",
    "fa-toggle-left":"\uF191",
    "fa-dot-circle-o":"\uF192",
    "fa-wheelchair":"\uF193",
    "fa-vimeo-square":"\uF194",
    "fa-turkish-lira":"\uF195",
    "fa-plus-square-o":"\uF196",
    "fa-space-shuttle":"\uF197",
    "fa-slack":"\uF198",
    "fa-envelope-square":"\uF199",
    "fa-wordpress":"\uF19a",
    "fa-openid":"\uF19b",
    "fa-university":"\uF19c",
    "fa-mortar-board":"\uF19d",
    "fa-yahoo":"\uF19e",
    "fa-google":"\uF1a0",
    "fa-reddit":"\uF1a1",
    "fa-reddit-square":"\uF1a2",
    "fa-stumbleupon-circle":"\uF1a3",
    "fa-stumbleupon":"\uF1a4",
    "fa-delicious":"\uF1a5",
    "fa-digg":"\uF1a6",
    "fa-pied-piper":"\uF1a7",
    "fa-pied-piper-alt":"\uF1a8",
    "fa-drupal":"\uF1a9",
    "fa-joomla":"\uF1aa",
    "fa-language":"\uF1ab",
    "fa-fax":"\uF1ac",
    "fa-building":"\uF1ad",
    "fa-child":"\uF1ae",
    "fa-paw":"\uF1b0",
    "fa-spoon":"\uF1b1",
    "fa-cube":"\uF1b2",
    "fa-cubes":"\uF1b3",
    "fa-behance":"\uF1b4",
    "fa-behance-square":"\uF1b5",
    "fa-steam":"\uF1b6",
    "fa-steam-square":"\uF1b7",
    "fa-recycle":"\uF1b8",
    "fa-car":"\uF1b9",
    "fa-taxi":"\uF1ba",
    "fa-tree":"\uF1bb",
    "fa-spotify":"\uF1bc",
    "fa-deviantart":"\uF1bd",
    "fa-soundcloud":"\uF1be",
    "fa-database":"\uF1c0",
    "fa-file-pdf-o":"\uF1c1",
    "fa-file-word-o":"\uF1c2",
    "fa-file-excel-o":"\uF1c3",
    "fa-file-powerpoint-o":"\uF1c4",
    "fa-file-picture-o":"\uF1c5",
    "fa-file-zip-o":"\uF1c6",
    "fa-file-sound-o":"\uF1c7",
    "fa-file-video-o":"\uF1c8",
    "fa-file-code-o":"\uF1c9",
    "fa-vine":"\uF1ca",
    "fa-codepen":"\uF1cb",
    "fa-jsfiddle":"\uF1cc",
    "fa-support":"\uF1cd",
    "fa-circle-o-notch":"\uF1ce",
    "fa-rebel":"\uF1d0",
    "fa-ge":"\uF1d1",
    "fa-git-square":"\uF1d2",
    "fa-git":"\uF1d3",
    "fa-hacker-news":"\uF1d4",
    "fa-tencent-weibo":"\uF1d5",
    "fa-qq":"\uF1d6",
    "fa-weixin":"\uF1d7",
    "fa-send":"\uF1d8",
    "fa-send-o":"\uF1d9",
    "fa-history":"\uF1da",
    "fa-circle-thin":"\uF1db",
    "fa-header":"\uF1dc",
    "fa-paragraph":"\uF1dd",
    "fa-sliders":"\uF1de",
    "fa-share-alt":"\uF1e0",
    "fa-share-alt-square":"\uF1e1",
    "fa-bomb":"\uF1e2",
    "fa-soccer-ball-o":"\uF1e3",
    "fa-tty":"\uF1e4",
    "fa-binoculars":"\uF1e5",
    "fa-plug":"\uF1e6",
    "fa-slideshare":"\uF1e7",
    "fa-twitch":"\uF1e8",
    "fa-yelp":"\uF1e9",
    "fa-newspaper-o":"\uF1ea",
    "fa-wifi":"\uF1eb",
    "fa-calculator":"\uF1ec",
    "fa-paypal":"\uF1ed",
    "fa-google-wallet":"\uF1ee",
    "fa-cc-visa":"\uF1f0",
    "fa-cc-mastercard":"\uF1f1",
    "fa-cc-discover":"\uF1f2",
    "fa-cc-amex":"\uF1f3",
    "fa-cc-paypal":"\uF1f4",
    "fa-cc-stripe":"\uF1f5",
    "fa-bell-slash":"\uF1f6",
    "fa-bell-slash-o":"\uF1f7",
    "fa-trash":"\uF1f8",
    "fa-copyright":"\uF1f9",
    "fa-at":"\uF1fa",
    "fa-eyedropper":"\uF1fb",
    "fa-paint-brush":"\uF1fc",
    "fa-birthday-cake":"\uF1fd",
    "fa-area-chart":"\uF1fe",
    "fa-pie-chart":"\uF200",
    "fa-line-chart":"\uF201",
    "fa-lastfm":"\uF202",
    "fa-lastfm-square":"\uF203",
    "fa-toggle-off":"\uF204",
    "fa-toggle-on":"\uF205",
    "fa-bicycle":"\uF206",
    "fa-bus":"\uF207",
    "fa-ioxhost":"\uF208",
    "fa-angellist":"\uF209",
    "fa-cc":"\uF20a",
    "fa-sheqel":"\uF20b",
    "fa-meanpath":"\uF20c",
};

export function registerFontAwesome() {
    registerIcons({
        fontFace: {
            fontFamily: "FontAwesome",
        },
        icons: fontAwesomeIcons,
    });

    registerIconAlias("chevrondown", "fa-chevron-down");
    registerIconAlias("chevronright", "fa-chevron-right");
    registerIconAlias("checkmark", "fa-check");
    registerIconAlias("more", "fa-ellipsis-h");
    registerIconAlias("clear", "fa-times");
    registerIconAlias("errorbadge", "fa-times-circle-o");
    registerIconAlias("completed", "fa-check-circle-o");
}