import { customizable, memoizeFunction, mergeStyleSets, IStyle, ITheme } from "office-ui-fabric-react";
import * as React from "react";

import { AcceptTermsFormField } from "./formcomponents/AcceptTermsFormField";
import { CaptchaFormField } from "./formcomponents/CaptchaFormField";
import { FormHeader } from "./formcomponents/FormHeader";
import { InstructionsFormField } from "./formcomponents/InstructionsFormField";
import { MultipleChoice } from "./formcomponents/MultipleChoice";
import { TextFormField } from "./formcomponents/TextFormField";
import { FieldSize } from "./formcomponents/formschema/FieldSize";
import { FieldType } from "./formcomponents/formschema/FieldType";
import { FormSchemaRow } from "./formcomponents/formschema/FormSchemaRow";
import { IAcceptTermsFieldSchema } from "./formcomponents/formschema/IAcceptTermsFieldSchema";
import { ICaptchaFieldSchema } from "./formcomponents/formschema/ICaptchaFieldSchema";
import { IFieldSchema } from "./formcomponents/formschema/IFieldSchema";
import { IFormHeaderSchema } from "./formcomponents/formschema/IFormHeaderSchema";
import { IMultipleChoiceFieldSchema } from "./formcomponents/formschema/IMultipleChoiceFieldSchema";
import { ITextFieldSchema } from "./formcomponents/formschema/ITextFieldSchema";

export interface IDynamicFormStyles {
    root?: IStyle;
    fieldRow?: IStyle;
    field?: IStyle;
    fieldQuarter?: IStyle;
    fieldThird?: IStyle;
    fieldHalf?: IStyle;
    fieldFull?: IStyle;
}

export interface IDynamicFormClassNames {
    root: string;
    fieldRow: string;
    fieldQuarter: string;
    fieldThird: string;
    fieldHalf: string;
    fieldFull: string;
}

export interface IFormData {
    [key: string]: string | undefined;
}

export interface IFormValidationState {
    [key: string]: boolean | undefined;
}

export interface IDynamicFormProps {
    schemaRows: ReadonlyArray<FormSchemaRow>;
    data: IFormData;
    onDataChange: (fieldUuid: string, newValue?: string) => void;
    onValidationChange: (fieldUuid: string, valid: boolean) => void;
    readOnly?: boolean;
    className?: string;
    styles?: IDynamicFormStyles;
    theme?: ITheme;
}

export const getClassNames = memoizeFunction(
    (theme: ITheme, styles: IDynamicFormStyles, className?: string): IDynamicFormClassNames => {
        const fieldGenericStyle: IStyle = {
            marginBottom: "10px",
            verticalAlign: "top",
        };

        return mergeStyleSets({
            root: [
                "ms-Grid",
                styles.root,
                className,
            ],
            fieldRow: [
                "ms-Grid-row",
                styles.fieldRow,
            ],
            fieldQuarter: [
                fieldGenericStyle,
                "ms-Grid-col",
                "ms-sm12",
                "ms-md6",
                "ms-lg3",
                styles.field,
                styles.fieldQuarter,
            ],
            fieldThird: [
                fieldGenericStyle,
                "ms-Grid-col",
                "ms-sm12",
                "ms-md12",
                "ms-lg4",
                styles.field,
                styles.fieldThird,
            ],
            fieldHalf: [
                fieldGenericStyle,
                "ms-Grid-col",
                "ms-sm12",
                "ms-md6",
                styles.field,
                styles.fieldHalf,
            ],
            fieldFull: [
                fieldGenericStyle,
                "ms-Grid-col",
                "ms-sm12",
                styles.field,
                styles.fieldFull,
            ],
        });
    });

@customizable("DynamicForm", ["theme", "styles"], true)
export class DynamicForm extends React.Component<IDynamicFormProps> {
    render() {
        const classNames = getClassNames(this.props.theme!, this.props.styles!, this.props.className);

        const dynamicForm = this;

        return (
            <div className={classNames.root}>
                {this.props.schemaRows.map((formSchemaRow: FormSchemaRow): JSX.Element => {
                    return (
                        <div key={formSchemaRow.uuid} className={classNames.fieldRow}>
                            {formSchemaRow.fields.map((fieldSchema: IFieldSchema): JSX.Element => {
                                function renderField() {
                                    switch (fieldSchema.type) {
                                    case FieldType.MultipleChoice:
                                        return <MultipleChoice
                                            schema={fieldSchema as IMultipleChoiceFieldSchema}
                                            value={dynamicForm.props.data[fieldSchema.uuid]}
                                            onValueChange={(fieldUuid: string, newValue?: string) => {dynamicForm.props.onDataChange(fieldUuid, newValue);}}
                                            onValidationChange={(fieldUuid: string, valid: boolean) => {dynamicForm.props.onValidationChange(fieldUuid, valid);}}
                                            readOnly={dynamicForm.props.readOnly}
                                            />;
                                    case FieldType.Text:
                                        return <TextFormField
                                            schema={fieldSchema as ITextFieldSchema}
                                            value={dynamicForm.props.data[fieldSchema.uuid]}
                                            onValueChange={(fieldUuid: string, newValue?: string) => {dynamicForm.props.onDataChange(fieldUuid, newValue);}}
                                            onValidationChange={(fieldUuid: string, valid: boolean) => {dynamicForm.props.onValidationChange(fieldUuid, valid);}}
                                            readOnly={dynamicForm.props.readOnly}
                                            />;
                                    case FieldType.Header:
                                        return <FormHeader
                                            schema={fieldSchema as IFormHeaderSchema}
                                            />;
                                    case FieldType.Captcha:
                                        return <CaptchaFormField
                                            schema={fieldSchema as ICaptchaFieldSchema}
                                            value={dynamicForm.props.data[fieldSchema.uuid]}
                                            onValueChange={(fieldUuid: string, newValue?: string) => {dynamicForm.props.onDataChange(fieldUuid, newValue);}}
                                            onValidationChange={(fieldUuid: string, valid: boolean) => {dynamicForm.props.onValidationChange(fieldUuid, valid);}}
                                            readOnly={dynamicForm.props.readOnly}
                                            />;
                                    case FieldType.AcceptTerms:
                                        return <AcceptTermsFormField
                                            schema={fieldSchema as IAcceptTermsFieldSchema}
                                            value={dynamicForm.props.data[fieldSchema.uuid]}
                                            onValueChange={(fieldUuid: string, newValue?: string) => {dynamicForm.props.onDataChange(fieldUuid, newValue);}}
                                            onValidationChange={(fieldUuid: string, valid: boolean) => {dynamicForm.props.onValidationChange(fieldUuid, valid);}}
                                            readOnly={dynamicForm.props.readOnly}
                                            />;
                                    case FieldType.Instructions:
                                        return <InstructionsFormField
                                            schema={fieldSchema as IFormHeaderSchema}
                                            />;
                                    }
                                }

                                let fieldClassName;
                                switch (fieldSchema.fieldSize) {
                                    case FieldSize.Quarter:
                                        fieldClassName = classNames.fieldQuarter;
                                        break;
                                    case FieldSize.Third:
                                        fieldClassName = classNames.fieldThird;
                                        break;
                                    case FieldSize.Half:
                                        fieldClassName = classNames.fieldHalf;
                                        break;
                                    case FieldSize.Full:
                                        fieldClassName = classNames.fieldFull;
                                        break;
                                }

                                return (
                                    <div
                                        key={fieldSchema.uuid}
                                        className={fieldClassName}
                                        >
                                        {renderField()}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }
}