import { customizable, memoizeFunction, mergeStyleSets, IStyle, ITheme } from "office-ui-fabric-react";
import * as React from "react";

import { IFormHeaderSchema } from "./formschema/IFormHeaderSchema";

export interface IFormHeaderStyles {
    root?: IStyle;
    text?: IStyle;
}

export interface IFormHeaderClassNames {
    root: string;
    text: string;
    rootDividier: string;
}

export interface IFormHeaderProps {
    schema: IFormHeaderSchema;
    className?: string;
    styles?: IFormHeaderStyles;
    theme?: ITheme;
}

export const getClassNames = memoizeFunction(
    (theme: ITheme, styles: IFormHeaderStyles, className?: string): IFormHeaderClassNames => {
        const formHeaderOffset = 18;
        return mergeStyleSets({
            root: [
                {
                    borderTopWidth: "1px",
                    borderTopStyle: "solid",
                    borderTopColor: theme.palette.neutralQuaternary,
                    marginTop: "10px",
                    marginLeft: `${formHeaderOffset*-1}px`,
                    marginRight: `${formHeaderOffset*-1}px`,
                    paddingLeft: `${formHeaderOffset}px`,
                    paddingRight: `${formHeaderOffset}px`,
                    paddingTop: "30px",
                },
                styles.root,
                className,
            ],
            text: [
                {
                    margin: 0,
                    marginBottom: "5px",
                },
                theme.fonts.large,
                styles.text,
            ],
            rootDividier: [
                {
                    paddingTop: "10px",
                },
            ],
        });
    });

@customizable("FormHeader", ["theme", "styles"], true)
export class FormHeader extends React.Component<IFormHeaderProps> {
    render() {
        const classNames = getClassNames(this.props.theme!, this.props.styles!, this.props.className);

        return (
            <div className={`${classNames.root} ${(this.props.schema.titleText.length === 0) ? classNames.rootDividier : ""}`}>
                {(this.props.schema.titleText.length > 0) && <div className={classNames.text}>{this.props.schema.titleText}</div>}
            </div>
        );
    }
}