import { classNamesFunction, customizable, Checkbox, ICheckboxProps, ICheckboxStyles, ICheckboxStyleProps, ITheme } from "office-ui-fabric-react";
import * as React from "react";

import { ErrorMessage } from "../../ErrorMessage";
import { InfoButtonCallout } from "../../InfoButtonCallout";
import { LinkText } from "../../LinkText";

import { IFieldProps } from "./IFieldProps";
import { IAcceptTermsFieldSchema } from "./formschema/IAcceptTermsFieldSchema";

const getCheckboxClassNames = classNamesFunction<ICheckboxStyleProps, ICheckboxStyles>();

export interface IAcceptTermsFormFieldProps extends IFieldProps<IAcceptTermsFieldSchema> {
    theme?: ITheme;
}

@customizable("AcceptTermsFormField", ["theme"], true)
export class AcceptTermsFormField extends React.Component<IAcceptTermsFormFieldProps> {
    render() {
        const checkboxClassNames = getCheckboxClassNames(undefined, {
            theme: this.props.theme!,
            className: undefined,
            disabled: false,
            checked: false,
            reversed: false,
            isUsingCustomLabelRender: false,
        });

        const errorMessage = (this.props.value === "") ? "This field is required." : undefined;

        const checkbox = <Checkbox
            label={this.props.schema.titleText}
            onRenderLabel={(props?: ICheckboxProps): JSX.Element | null => <span className={checkboxClassNames.label}><LinkText singleLine={true} text={(props && props.label) ? props.label : ""} /></span>}
            checked={this.props.value === "true"}
            onChange={(ev, checked) => {this.props.onValueChange(this.props.schema.uuid, checked ? "true" : ""); this.props.onValidationChange(this.props.schema.uuid, !!checked); }}
            disabled={this.props.readOnly}
            />;

        return (
            <>
                <div>
                    {!this.props.schema.tooltipVisible && checkbox}
                    {this.props.schema.tooltipVisible && 
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                            {checkbox}
                            <InfoButtonCallout>
                                <LinkText text={this.props.schema.tooltipText} />
                            </InfoButtonCallout>
                        </div>}
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            </>
        );
    }
}