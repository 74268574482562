import update from "immutability-helper";
import { customizable, memoizeFunction, mergeStyleSets, DefaultButton, ImageCoverStyle, IContextualMenuItem, IStyle, ITheme } from "office-ui-fabric-react";
import * as React from "react";
import { DraggableCore, DraggableData } from "react-draggable";
import * as UUID from "uuid";

import { Point, Rect, Size } from "../../utils/Geometry";

import { AcceptTermsFormFieldEditor } from "./formcomponents/AcceptTermsFormFieldEditor";
import { CaptchaFormFieldEditor } from "./formcomponents/CaptchaFormFieldEditor";
import { FormHeaderFieldEditor } from "./formcomponents/FormHeaderFieldEditor";
import { InstructionsFormFieldEditor } from "./formcomponents/InstructionsFormFieldEditor";
import { MultipleChoiceFormFieldEditor } from "./formcomponents/MultipleChoiceFormFieldEditor";
import { TextFormFieldEditor } from "./formcomponents/TextFormFieldEditor";
import { FieldSize } from "./formcomponents/formschema/FieldSize";
import { FieldType } from "./formcomponents/formschema/FieldType";
import { FormSchemaRow } from "./formcomponents/formschema/FormSchemaRow";
import { CreateNewAcceptTermsFieldSchema, IAcceptTermsFieldSchema } from "./formcomponents/formschema/IAcceptTermsFieldSchema";
import { CreateNewCaptchaFieldSchema, ICaptchaFieldSchema } from "./formcomponents/formschema/ICaptchaFieldSchema";
import { IFieldSchema } from "./formcomponents/formschema/IFieldSchema";
import { CreateNewFormHeaderSchema, IFormHeaderSchema } from "./formcomponents/formschema/IFormHeaderSchema";
import { CreateNewInstructionsFieldSchema, IInstructionsFieldSchema } from "./formcomponents/formschema/IInstructionsFieldSchema";
import { CreateNewMultipleChoiceSchema, IMultipleChoiceFieldSchema } from "./formcomponents/formschema/IMultipleChoiceFieldSchema";
import { getKnownTypeInfo, knownTypes, knownTypeToString, CreateNewTextFieldSchema, ITextFieldSchema, TextFieldSchemaKnownType } from "./formcomponents/formschema/ITextFieldSchema";

export interface IDynamicFormEditorStyles {
    root?: IStyle;
    fieldEditors?: IStyle;
    fieldRow?: IStyle;
    fieldPlaceholder?: IStyle;
    fieldPlaceholderQuarter?: IStyle;
    fieldPlaceholderThird?: IStyle;
    fieldPlaceholderHalf?: IStyle;
    fieldPlaceholderFull?: IStyle;
    fieldPlaceholderDragging?: IStyle;
}

export interface IDynamicFormEditorClassNames {
    root: string;
    fieldEditors: string;
    fieldRow: string;
    fieldPlaceholderQuarter: string;
    fieldPlaceholderThird: string;
    fieldPlaceholderHalf: string;
    fieldPlaceholderFull: string;
    fieldPlaceholderDragging: string;
}

export interface IDynamicFormEditorProps {
    defaultSchemaRows?: FormSchemaRow[];
    schemaRows?: FormSchemaRow[];
    onSchemaRowsChange?: (newSchemaRows: ReadonlyArray<FormSchemaRow>) => void;
    className?: string;
    styles?: IDynamicFormEditorStyles;
    theme?: ITheme;
}

export interface IDynamicFormEditorState {
    schemaRows: FormSchemaRow[];
    draggingField: string;
    draggingFieldPlaceholderSize: Size;
    draggingFieldPositionRelativeToDragRoot: Point;
}

export const getClassNames = memoizeFunction(
(theme: ITheme, styles: IDynamicFormEditorStyles, className?: string): IDynamicFormEditorClassNames => {
    const fieldSpacing = 5;

    const fieldPlaceholderGenericStyle: IStyle = {
        marginLeft: `${fieldSpacing}px`,
        marginRight: `${fieldSpacing}px`,
        display: "inline-block",
        verticalAlign: "top",
    };

    return mergeStyleSets({
        root: [
            {
            },
            styles.root,
            className,
        ],
        fieldEditors: [
            {
                position: "relative",
                overflow: "hidden",
            },
            styles.fieldEditors,
        ],
        fieldRow: [
            {
                marginLeft: `-${fieldSpacing}px`,
                marginRight: `-${fieldSpacing}px`,
                paddingBottom: `${fieldSpacing*2}px`,
            },
            styles.fieldRow,
        ],
        fieldPlaceholderQuarter: [
            fieldPlaceholderGenericStyle,
            {
                width: `calc(25% - ${fieldSpacing*2}.1px)`,
            },
            styles.fieldPlaceholder,
            styles.fieldPlaceholderQuarter,
        ],
        fieldPlaceholderThird: [
            fieldPlaceholderGenericStyle,
            {
                width: `calc(33.333% - ${fieldSpacing*2}.1px)`,
            },
            styles.fieldPlaceholder,
            styles.fieldPlaceholderThird,
        ],
        fieldPlaceholderHalf: [
            fieldPlaceholderGenericStyle,
            {
                width: `calc(50% - ${fieldSpacing*2}.1px)`,
            },
            styles.fieldPlaceholder,
            styles.fieldPlaceholderHalf,
        ],
        fieldPlaceholderFull: [
            fieldPlaceholderGenericStyle,
            {
                width: `calc(100% - ${fieldSpacing*2}.1px)`,
            },
            styles.fieldPlaceholder,
            styles.fieldPlaceholderFull,
        ],
        fieldPlaceholderDragging: [
            {
                border: "1px",
                borderStyle: "solid",
                borderColor: theme.palette.neutralQuaternary,
                backgroundColor: theme.palette.accent,
            },
            styles.fieldPlaceholderDragging,
        ],
    });
});

@customizable("DynamicFormEditor", ["theme", "styles"], true)
export class DynamicFormEditor extends React.Component<IDynamicFormEditorProps, IDynamicFormEditorState> {
    private fieldEditorsDivRef: React.RefObject<HTMLDivElement>;
    private draggingRelativeMovement: Point;

    constructor(props: IDynamicFormEditorProps) {
        super(props);

        let defaultSchemaRows = this.props.defaultSchemaRows;
        if (!defaultSchemaRows) {
            let row = new FormSchemaRow([CreateNewMultipleChoiceSchema(), CreateNewMultipleChoiceSchema()]);
            row.fields[0].fieldSize = FieldSize.Half;
            row.fields[1].fieldSize = FieldSize.Half;

            let row2 = new FormSchemaRow([CreateNewTextFieldSchema(), CreateNewTextFieldSchema(), CreateNewTextFieldSchema(), CreateNewTextFieldSchema()]);
            row2.fields[0].fieldSize = FieldSize.Quarter;
            row2.fields[1].fieldSize = FieldSize.Quarter;
            row2.fields[2].fieldSize = FieldSize.Quarter;
            row2.fields[3].fieldSize = FieldSize.Quarter;

            defaultSchemaRows = [
                row,
                row2,
            ];
        }

        this.state = {
            schemaRows: defaultSchemaRows,
            draggingField: "",
            draggingFieldPlaceholderSize: new Size(0, 0),
            draggingFieldPositionRelativeToDragRoot: new Point(0, 0),
        };

        this.fieldEditorsDivRef = React.createRef<HTMLDivElement>();
        this.draggingRelativeMovement = new Point(0, 0);
    }

    private getRowContainingField(fieldUuid: string): FormSchemaRow | undefined {
        return this.getSchemaRows().find((checkingFormSchemaRow: FormSchemaRow) => !!checkingFormSchemaRow.getField(fieldUuid));
    }

    // newBaseState allows for updating other state at the same time as the schema rows
    private updateSchemaRows = (newSchemaRows: ReadonlyArray<FormSchemaRow>, newBaseState?: IDynamicFormEditorState) => {
        this.setState(update(newBaseState || this.state, {schemaRows: {$set: newSchemaRows}}));

        if (this.props.onSchemaRowsChange) {
            this.props.onSchemaRowsChange(newSchemaRows);
        }
    }

    private getSchemaRows(): ReadonlyArray<FormSchemaRow> {
        return this.props.schemaRows || this.state.schemaRows;
    }

    private onFieldSchemaUpdate = <T extends IFieldSchema>(fieldUuid: string, newSchema: T | null): void => {
        const row = this.getRowContainingField(fieldUuid);
        if (!row) {
            return;
        }

        const rowIndex = this.getSchemaRows().indexOf(row);

        const fieldIndex = row.getFieldIndex(fieldUuid);

        if (newSchema === null) {
            if (row.fields.length === 1) {
                // Remove entire row if we are removing the last field from it
                this.updateSchemaRows(update(this.getSchemaRows(), {$splice: [[rowIndex, 1]]}));
            } else {
                this.updateSchemaRows(update(this.getSchemaRows(), {[rowIndex]: {fields: {$splice: [[fieldIndex, 1]]}}}));
            }
        } else {
            this.updateSchemaRows(update(this.getSchemaRows(), {[rowIndex]: {fields: {$splice: [[fieldIndex, 1, newSchema]]}}}));
        }
    }

    private onAddFieldClick(fieldSchema: IFieldSchema) {
        this.updateSchemaRows(update(this.getSchemaRows(), {$push: [new FormSchemaRow([fieldSchema])]}));
    }

    private onFieldDragStart(fieldSchema: IFieldSchema, data: DraggableData) {
        if (this.fieldEditorsDivRef.current) {
            const draggableRect = data.node.getBoundingClientRect();
            const dragRootRect = this.fieldEditorsDivRef.current.getBoundingClientRect();

            this.draggingRelativeMovement = new Point(0, 0);

            this.setState(update(this.state, {
                draggingFieldPositionRelativeToDragRoot: {$set: new Point(draggableRect.left-dragRootRect.left, draggableRect.top-dragRootRect.top)},
                draggingFieldPlaceholderSize: {$set: new Size(draggableRect.width, draggableRect.height)},
                draggingField: {$set: ""},
            }));
        }
    }

    private onFieldDragStop(fieldSchema: IFieldSchema) {
        this.setState(update(this.state, {draggingField: {$set: ""}}));
    }

    private onFieldDrag(draggingFieldSchema: IFieldSchema, event: React.MouseEvent<HTMLElement | SVGElement> | React.TouchEvent<HTMLElement | SVGElement> | MouseEvent | TouchEvent, data: DraggableData) {
        if (this.fieldEditorsDivRef.current) {
            const dragRoot = this.fieldEditorsDivRef.current;
            let newState = this.state;
            let newSchemaRows = this.getSchemaRows();

            // Update dragging position
            this.draggingRelativeMovement = this.draggingRelativeMovement.plus(new Point(data.deltaX, data.deltaY));

            newState = update(newState, {
                draggingFieldPositionRelativeToDragRoot: {$set: new Point(this.state.draggingFieldPositionRelativeToDragRoot.x + data.deltaX, this.state.draggingFieldPositionRelativeToDragRoot.y + data.deltaY)},
            });

            // Mark as dragging if moved more then 5px in any direction
            if (this.state.draggingField !== draggingFieldSchema.uuid) {
                const sqDistMoved = this.draggingRelativeMovement.x * this.draggingRelativeMovement.x
                    + this.draggingRelativeMovement.y * this.draggingRelativeMovement.y;

                const minPixelsMoved = 5;

                if (sqDistMoved > (minPixelsMoved*minPixelsMoved)) {
                    newState = update(newState, {
                        draggingField: {$set: draggingFieldSchema.uuid},
                    });
                }
            }

            // Check for overlap
            if (newState.draggingField !== "") {
                const dragRootRect = Rect.fromClientRect(this.fieldEditorsDivRef.current.getBoundingClientRect());

                const dragTargetPointRelativeToDragRoot = newState.draggingFieldPositionRelativeToDragRoot.plus(new Point(newState.draggingFieldPlaceholderSize.width/2, newState.draggingFieldPlaceholderSize.height/2));

                // Holds the index of the targeted row (or the row prior if targeting between rows)
                let targetRowIndex: number = -1;
                let targetBetweenRows = true;

                Array.from(dragRoot.childNodes).forEach((child: ChildNode, key: number) => {
                    if (child instanceof HTMLDivElement) {
                        const FormSchemaRowElement = child;
                        const FormSchemaRowElementRect = Rect.fromClientRect(FormSchemaRowElement.getBoundingClientRect());
                        const FormSchemaRowElementRectRelativeToDragRoot = FormSchemaRowElementRect.translate(dragRootRect.getPoint().timesScalar(-1));

                        const childDraggingPlaceholderRectYTopThird = FormSchemaRowElementRectRelativeToDragRoot.y + 0.2*FormSchemaRowElementRectRelativeToDragRoot.height;
                        const childDraggingPlaceholderRectYBottomThird = FormSchemaRowElementRectRelativeToDragRoot.y + 0.8*FormSchemaRowElementRectRelativeToDragRoot.height;

                        if (dragTargetPointRelativeToDragRoot.y > childDraggingPlaceholderRectYBottomThird) {
                            targetRowIndex = key;
                            targetBetweenRows = true;
                        } else if (dragTargetPointRelativeToDragRoot.y > childDraggingPlaceholderRectYTopThird) {
                            targetRowIndex = key;
                            targetBetweenRows = false;
                        }
                    }
                });

                const draggingFieldSourceRow = this.getRowContainingField(draggingFieldSchema.uuid);
                if (!draggingFieldSourceRow) {
                    console.error("Dragging field's source row could not be found.");
                    return;
                }

                const draggingFieldSourceRowIndex = newSchemaRows.indexOf(draggingFieldSourceRow);

                const draggingFieldSourceRowPositionIndex = draggingFieldSourceRow.getFieldIndex(draggingFieldSchema.uuid);

                const isDraggingWholeRow = (draggingFieldSourceRow.fields.length === 1);

                const isDragPositionBeforeCurrentRow = targetBetweenRows && ((targetRowIndex + 1) === draggingFieldSourceRowIndex);
                const isDragPositionAfterCurrentRow = targetBetweenRows && (targetRowIndex === draggingFieldSourceRowIndex);

                if (targetBetweenRows) {
                    if (isDraggingWholeRow && (isDragPositionBeforeCurrentRow || isDragPositionAfterCurrentRow)) {
                        // Nothing to do. We're dragging the only item in a row within the space that row occupies
                    } else {
                        //console.log("Perform move to new row");
                        // Remove from old location
                        if (isDraggingWholeRow) {
                            newSchemaRows = update(newSchemaRows, {$splice: [[draggingFieldSourceRowIndex, 1]]});

                            if (targetRowIndex > draggingFieldSourceRowIndex) {
                                // We're removing the source row from the list, then re-adding it at the new location. If the re-add location
                                // is after the removal location, then the index will be shifted lower by the remove.
                                targetRowIndex--;
                            }
                        } else {
                            newSchemaRows = update(newSchemaRows, {[draggingFieldSourceRowIndex]: {uuid: {$set: UUID.v4()}, fields: {$splice: [[draggingFieldSourceRowPositionIndex, 1]]}}});
                        }

                        // Insert new row at location. The old row has had it's UUID updated (making it the new row from React's perspective).
                        // The row containing the dragging element needs to maintain a stable UUID, or react will reconstruct the draggable control
                        // loosing the drag state.
                        newSchemaRows = update(newSchemaRows, {$splice: [[targetRowIndex + 1, 0, new FormSchemaRow([draggingFieldSchema], draggingFieldSourceRow.uuid)]]});
                    }
                } else {
                    let targetFieldIndex: number = -1;
                    Array.from(dragRoot.childNodes[targetRowIndex].childNodes).forEach((child: ChildNode, key: number) => {
                        if (child instanceof HTMLDivElement) {
                            const fieldElementRect = Rect.fromClientRect(child.getBoundingClientRect());
                            const fieldElementRectRelativeToDragRoot = fieldElementRect.translate(dragRootRect.getPoint().timesScalar(-1));

                            const elementXMiddle = fieldElementRectRelativeToDragRoot.x + 0.5*fieldElementRectRelativeToDragRoot.width;

                            if (dragTargetPointRelativeToDragRoot.x > elementXMiddle) {
                                targetFieldIndex = key;
                            }
                        }
                    });

                    const isTargetingSameRow = (targetRowIndex === draggingFieldSourceRowIndex);

                    const isDragPositionBeforeCurrentFieldPosition = ((targetFieldIndex + 1) === draggingFieldSourceRowPositionIndex);
                    const isDragPositionAfterCurrentFieldPosition = (targetFieldIndex === draggingFieldSourceRowPositionIndex);

                    if (isTargetingSameRow) {
                        if (!isDragPositionBeforeCurrentFieldPosition && !isDragPositionAfterCurrentFieldPosition) {
                            //console.log("Move to new location in same row: " + targetFieldIndex + " " + draggingFieldSourceRowPositionIndex);
                            newSchemaRows = update(newSchemaRows, {[targetRowIndex]: {fields: {$splice: [[draggingFieldSourceRowPositionIndex, 1], [(targetFieldIndex > draggingFieldSourceRowPositionIndex) ? targetFieldIndex : targetFieldIndex+1, 0, draggingFieldSchema]]}}});
                        }
                    } else if (!newSchemaRows[targetRowIndex].isFull()) {
                        //console.log("Move to new location in different row: " + targetFieldIndex + " " + draggingFieldSourceRowPositionIndex + " " + targetRowIndex);

                        // Remove from old location
                        if (isDraggingWholeRow) {
                            newSchemaRows = update(newSchemaRows, {$splice: [[draggingFieldSourceRowIndex, 1]]});

                            if (targetRowIndex > draggingFieldSourceRowIndex) {
                                // We're removing the source row from the list, then re-adding it at the new location. If the re-add location
                                // is after the removal location, then the index will be shifted lower by the remove.
                                targetRowIndex--;
                            }
                        } else {
                            newSchemaRows = update(newSchemaRows, {[draggingFieldSourceRowIndex]: {uuid: {$set: UUID.v4()}, fields: {$splice: [[draggingFieldSourceRowPositionIndex, 1]]}}});
                        }

                        newSchemaRows = update(newSchemaRows, {[targetRowIndex]: {uuid: {$set: draggingFieldSourceRow.uuid}, fields: {$splice: [[targetFieldIndex+1, 0, draggingFieldSchema]]}}});
                    }
                }
            }

            if (newSchemaRows === this.getSchemaRows()) {
                this.setState(newState);
            } else {
                this.updateSchemaRows(newSchemaRows, newState);
            }
        }
    }

    render() {
        const classNames = getClassNames(this.props.theme!, this.props.styles!, this.props.className);
        const schemaRows = this.getSchemaRows();

        return (
            <div className={classNames.root}>
                <div ref={this.fieldEditorsDivRef} className={classNames.fieldEditors}>
                    {schemaRows.map((formSchemaRow: FormSchemaRow): JSX.Element => {
                        const columnsOccupiedInRow = formSchemaRow.getColumnsOccupied();

                        return (
                            <div key={formSchemaRow.uuid} className={classNames.fieldRow}>
                                {formSchemaRow.fields.map((fieldSchema: IFieldSchema): JSX.Element => {
                                    const getFieldEditor = () => {
                                        switch (fieldSchema.type) {
                                        case FieldType.MultipleChoice:
                                            return <MultipleChoiceFormFieldEditor
                                                        schema={fieldSchema as IMultipleChoiceFieldSchema}
                                                        onSchemaChange={this.onFieldSchemaUpdate}
                                                        columnsOccupiedInRow={columnsOccupiedInRow}
                                                        />;
                                        case FieldType.Text:
                                            return <TextFormFieldEditor
                                                        schema={fieldSchema as ITextFieldSchema}
                                                        onSchemaChange={this.onFieldSchemaUpdate}
                                                        columnsOccupiedInRow={columnsOccupiedInRow}
                                                        />;
                                        case FieldType.Header:
                                            return <FormHeaderFieldEditor
                                                        schema={fieldSchema as IFormHeaderSchema}
                                                        onSchemaChange={this.onFieldSchemaUpdate}
                                                        columnsOccupiedInRow={12}
                                                        />;
                                        case FieldType.Captcha:
                                            return <CaptchaFormFieldEditor
                                                        schema={fieldSchema as ICaptchaFieldSchema}
                                                        onSchemaChange={this.onFieldSchemaUpdate}
                                                        columnsOccupiedInRow={columnsOccupiedInRow}
                                                        />;
                                        case FieldType.AcceptTerms:
                                            return <AcceptTermsFormFieldEditor
                                                        schema={fieldSchema as IAcceptTermsFieldSchema}
                                                        onSchemaChange={this.onFieldSchemaUpdate}
                                                        columnsOccupiedInRow={columnsOccupiedInRow}
                                                        />;
                                        case FieldType.Instructions:
                                            return <InstructionsFormFieldEditor
                                                        schema={fieldSchema as IInstructionsFieldSchema}
                                                        onSchemaChange={this.onFieldSchemaUpdate}
                                                        columnsOccupiedInRow={columnsOccupiedInRow}
                                                        />;
                                        }
                                    };

                                    const isDraggingField = (fieldSchema.uuid === this.state.draggingField);

                                    let style: React.CSSProperties = {};
                                    let placeholderStyle: React.CSSProperties = {};
                                    if (isDraggingField) {
                                        style.position="absolute";
                                        style.left = "0";
                                        style.top = "0";
                                        style.transform = `translate(${this.state.draggingFieldPositionRelativeToDragRoot.x}px, ${this.state.draggingFieldPositionRelativeToDragRoot.y}px)`;
                                        style.width = `${this.state.draggingFieldPlaceholderSize.width}px`;
                                        style.height = `${this.state.draggingFieldPlaceholderSize.height}px`;
                                        style.zIndex = 2;

                                        placeholderStyle.width = `${this.state.draggingFieldPlaceholderSize.width}px`;
                                        placeholderStyle.height = `${this.state.draggingFieldPlaceholderSize.height}px`;
                                    }

                                    let fieldPlaceholderClassName;
                                    switch (fieldSchema.fieldSize) {
                                        case FieldSize.Quarter:
                                            fieldPlaceholderClassName = classNames.fieldPlaceholderQuarter;
                                            break;
                                        case FieldSize.Third:
                                            fieldPlaceholderClassName = classNames.fieldPlaceholderThird;
                                            break;
                                        case FieldSize.Half:
                                            fieldPlaceholderClassName = classNames.fieldPlaceholderHalf;
                                            break;
                                        case FieldSize.Full:
                                            fieldPlaceholderClassName = classNames.fieldPlaceholderFull;
                                            break;
                                    }

                                    return (
                                        <div
                                            key={fieldSchema.uuid}
                                            className={`${fieldPlaceholderClassName} ${isDraggingField ? classNames.fieldPlaceholderDragging : ""}`}
                                            style={placeholderStyle}
                                            >
                                            <DraggableCore
                                                onStart={(event, data) => this.onFieldDragStart(fieldSchema, data)}
                                                onStop={(event, data) => this.onFieldDragStop(fieldSchema)}
                                                onDrag={(event, data) => this.onFieldDrag(fieldSchema, event, data)}
                                                cancel=".FormEditor-nodrag"
                                                offsetParent={this.fieldEditorsDivRef.current ? this.fieldEditorsDivRef.current : undefined}
                                                >
                                                <div style={style}>{getFieldEditor()}</div>
                                            </DraggableCore>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
                <DefaultButton
                    text="Add Field"
                    menuProps={{
                        shouldFocusOnMount: true,
                        items: [
                            {
                                key: "instructions",
                                text: "Instructions",
                                iconProps: { iconName: "fa-align-left" },
                                onClick: () => this.onAddFieldClick(CreateNewInstructionsFieldSchema()),
                            },
                            {
                                key: "multiplechoice",
                                text: "Multiple Choice",
                                iconProps: { iconName: "fa-list" },
                                onClick: () => this.onAddFieldClick(CreateNewMultipleChoiceSchema()),
                            },
                            {
                                key: "text",
                                text: "Text",
                                iconProps: { iconName: "fa-font" },
                                onClick: () => this.onAddFieldClick(CreateNewTextFieldSchema()),
                                split: true,
                                subMenuProps: {
                                    items: knownTypes().map((knownType: TextFieldSchemaKnownType): IContextualMenuItem => {
                                        const knownTypeInfo = getKnownTypeInfo(knownType);
                                        return {
                                            key: knownTypeToString(knownType),
                                            text: knownTypeInfo.text,
                                            iconProps: knownTypeInfo.iconProps,
                                            onClick: () => this.onAddFieldClick(CreateNewTextFieldSchema(knownType)),
                                        };
                                    }),
                                },
                            },
                            {
                                key: "header",
                                text: "Header",
                                iconProps: { iconName: "fa-bookmark-o" },
                                onClick: () => this.onAddFieldClick(CreateNewFormHeaderSchema()),
                            },
                            {
                                key: "captcha",
                                text: "Captcha",
                                iconProps: { iconName: "fa-shield" },
                                onClick: () => this.onAddFieldClick(CreateNewCaptchaFieldSchema()),
                            },
                            {
                                key: "acceptterms",
                                text: "Accept Terms",
                                iconProps: { iconName: "fa-legal" },
                                onClick: () => this.onAddFieldClick(CreateNewAcceptTermsFieldSchema()),
                            },
                        ],
                    }}
                    />
            </div>
        );
    }
}